import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PageRepositoryService } from 'src/lib/repository/abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class TmTopApi extends PageRepositoryService<any> {
  override baseUri = `/pms/temairazu/top`;

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getNotification(mrhstId: number): Observable<any> {
    return this.http
      .get<any>(`${this.apiServerUrl}${this.baseUri}/notification/${mrhstId}`)
      .pipe(
        map((res) => res),
        this.retryUncompleteError(1),
      );
  }

  getHotel(mrhstId: number): Observable<any> {
    return this.http
      .get<any>(`${this.apiServerUrl}${this.baseUri}/hotel/${mrhstId}`)
      .pipe(
        map((res) => res),
        this.retryUncompleteError(1),
      );
  }
}
