import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  delay,
  MonoTypeOperatorFunction,
  Observable,
  OperatorFunction,
  Subject,
  tap,
} from 'rxjs';
import { DialogService } from '../../app/services/dialog.service';

/**
 * 대화창 표시 지연 시간 (millisecond)
 */
const ALERT_DELAY_TIME = 100;

/**
 * 전역 통신 요청 Handler
 */
@Injectable({ providedIn: 'root' })
export class GlobalRequestHandler {
  private static afterCreate$ = new Subject<string | undefined>();

  private static afterUpdate$ = new Subject<string | undefined>();

  private static afterDelete$ = new Subject<string | undefined>();

  private static requestError$ = new Subject<HttpErrorResponse>();

  constructor(
    private dialogService: DialogService,
    // private toastService: ToastService
  ) {
    this.bindEvents();
  }

  static getAfterCreate$(): Observable<string | undefined> {
    return GlobalRequestHandler.afterCreate$.asObservable();
  }

  static getAfterUpdate$(): Observable<string | undefined> {
    return GlobalRequestHandler.afterUpdate$.asObservable();
  }

  static getAfterDelete$(): Observable<string | undefined> {
    return GlobalRequestHandler.afterDelete$.asObservable();
  }

  static getRequestError$(): Observable<HttpErrorResponse> {
    return GlobalRequestHandler.requestError$.asObservable();
  }

  static onAfterCreate$(
    completeMessage?: string,
  ): MonoTypeOperatorFunction<any> {
    return tap(() => {
      GlobalRequestHandler.afterCreate$.next(completeMessage);
    });
  }

  static onAfterUpdate$(
    completeMessage?: string,
  ): MonoTypeOperatorFunction<any> {
    return tap(() => {
      GlobalRequestHandler.afterUpdate$.next(completeMessage);
    });
  }

  static onAfterDelete$(
    completeMessage?: string,
  ): MonoTypeOperatorFunction<any> {
    return tap(() => {
      GlobalRequestHandler.afterDelete$.next(completeMessage);
    });
  }

  static onRequestError$(): OperatorFunction<any, any> {
    return catchError((error) => {
      GlobalRequestHandler.requestError$.next(error);
      throw error;
    });
  }

  private bindEvents(): void {
    // 생성 성공
    GlobalRequestHandler.afterCreate$
      .pipe(
        delay(ALERT_DELAY_TIME),
        tap((msg) => {
          if (msg === '') {
            return;
          }

          this.dialogService.alert(msg || 'DIALOG.Com_Msg').subscribe();
        }),
      )
      .subscribe();

    // 업데이트 성공
    GlobalRequestHandler.afterUpdate$
      .pipe(
        delay(ALERT_DELAY_TIME),
        tap((msg) => {
          if (msg === '') {
            return;
          }

          this.dialogService.alert(msg || 'DIALOG.Com_Msg').subscribe();
        }),
      )
      .subscribe();

    // 삭제 성공
    GlobalRequestHandler.afterDelete$
      .pipe(
        delay(ALERT_DELAY_TIME),
        tap((msg) => {
          if (msg === '') {
            return;
          }

          this.dialogService.alert(msg || 'DIALOG.Del_Msg').subscribe();
        }),
      )
      .subscribe();

    // 요청 오류
    GlobalRequestHandler.requestError$
      .pipe(
        delay(ALERT_DELAY_TIME),
        tap((error) => {
          let errorMessage = 'DIALOG.Err_Msg';

          if (error?.error?.message) {
            errorMessage = error?.error?.message;
          }

          this.dialogService.alert(errorMessage).subscribe();
        }),
      )
      .subscribe();
  }
}
