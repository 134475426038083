import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import {
  Observable,
  Subject,
  catchError,
  filter,
  finalize,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

class AuthInterceptorFn {
  /**
   * 토큰 갱신중
   */
  private static isRefreshing = false;

  /**
   * 토큰 갱신 완료 후 재호출 서브젝트
   */
  private static recall: Subject<boolean> = new Subject();

  static #authService: AuthService;

  private static get authService(): AuthService {
    if (!AuthInterceptorFn.#authService) {
      AuthInterceptorFn.#authService = inject(AuthService);
    }

    return AuthInterceptorFn.#authService;
  }

  static interceptor(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
  ): Observable<HttpEvent<unknown>> {
    // 토큰 전송하지 말아야 할 주소
    const hasExcluded = [`${environment.apiServerUrl}/oauth`].some(
      (excluded) => req.url.indexOf(excluded) >= 0,
    );

    // 토큰 전송하지 말아야 할 주소이면
    if (hasExcluded) {
      // 토큰 없이 요청
      return next(req);
    }

    return next(AuthInterceptorFn.getTokenAddedRequest(req)).pipe(
      catchError((error) => {
        // 통신 응답이 401이면
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // 토큰 갱신 후 재요청
          return AuthInterceptorFn.getRefreshedRequest(req, next);
        }

        return throwError(() => error);
      }),
    );
  }

  /**
   * 액세스 토큰 담긴 요청 획득
   */
  private static getTokenAddedRequest(
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    const tokenAddedRequest: HttpRequest<unknown> = request.clone({
      setHeaders: {
        Authorization: `${AuthInterceptorFn.authService.tokenType} ${AuthInterceptorFn.authService.accessToken}`,
      },
    });

    return tokenAddedRequest;
  }

  /**
   * 토큰 갱신 후 재요청
   */
  private static getRefreshedRequest(
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
  ): Observable<any> {
    // 갱신중일땐
    if (AuthInterceptorFn.isRefreshing) {
      // 갱신 완료됐을때 대기중인 요청 실행. 순서는 보장할수 없음.
      return AuthInterceptorFn.recall.pipe(
        filter((ready) => ready),
        // 한번만 실행. 없으면 토큰 갱신할때 이전에 실행했던 API들이 호출될수 있음.
        take(1),
        // 같은 옵저버블이 여러번 발생하면 마지막것만 실행
        switchMap(() => {
          return next(AuthInterceptorFn.getTokenAddedRequest(request));
        }),
      );
    }

    // 갱신 시작
    AuthInterceptorFn.isRefreshing = true;
    AuthInterceptorFn.recall.next(false);

    return AuthInterceptorFn.authService.getRefreshAuth$().pipe(
      // 같은 옵저버블이 여러번 발생하면 마지막것만 실행
      switchMap(() => {
        // 갱신 성공
        return next(AuthInterceptorFn.getTokenAddedRequest(request));
      }),
      tap(() => {
        // 대기중인 요청들 실행
        AuthInterceptorFn.recall.next(true);
      }),
      catchError((error) => {
        // TODO: 갱신 실패시 로그아웃 메시지 검토
        AuthInterceptorFn.authService.logout('invalid_token', true);
        return throwError(() => error);
      }),
      finalize(() => {
        // 갱신 종료
        AuthInterceptorFn.isRefreshing = false;
      }),
    );
  }
}

/**
 * 인증 인터셉터 Fn
 */
export const authInterceptor: HttpInterceptorFn = AuthInterceptorFn.interceptor;
