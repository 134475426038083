/* eslint-disable no-console */
import { ActivatedRouteSnapshot } from '@angular/router';
import dayjs, { Dayjs } from 'dayjs';
import { startCase } from 'lodash-es';

export type Nullable<T> =
  | {
      [P in keyof T]?: T[P] | null;
    }
  | null;

/**
 * Dayjs 일자 포맷
 */
export const DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Dayjs 일시 포맷
 */
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/**
 * [요일 인덱스]: 요일 오브젝트
 */
export const INDEX_TO_DOW: Record<number, string> = {
  0: 'SUNDAY',
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
};

/**
 * [요일]: 요일 인덱스 오브젝트
 */
export const DOW_TO_INDEX: Record<string, number> = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

/**
 * 부트스트랩 뷰포트 크기 타입
 */
export type ViewportSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

/**
 * 각종 유용한 기능들 모음
 */
export class Utils {
  /**
   * 문자인증에 사용되는 uuid
   */
  static getUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // y의 자리에는 8, 9, a, b 가능
      const v = c === 'x' ? Math.random() * 16 : Math.random() * 4 + 8;
      return Math.floor(v).toString(16);
    });
  }

  /**
   * 플러터 웹앱 여부
   */
  static isApp(): boolean {
    return window.navigator.userAgent.indexOf('paywith-flutter-app') >= 0;
  }

  /**
   * 플러터 웹앱 안드로이드 여부
   */
  static isAndroidApp(): boolean {
    return window.navigator.userAgent.indexOf('Android;') >= 0;
  }

  /**
   * 플러터 웹앱 ios 여부
   */
  static isIosApp(): boolean {
    return window.navigator.userAgent.indexOf('iPhone;') >= 0;
  }

  /**
   * 플러터 웹앱 안드로이드, ios 이외 여부
   */
  static isElseApp(): boolean {
    return this.isApp() && !this.isAndroidApp() && !this.isIosApp();
  }

  /**
   * 모바일(웹/앱) 여부를 화면 크기로 판단
   */
  static isMobileSize(): boolean {
    return window.innerWidth <= 768;
  }

  /**
   * userAgent가 모바일 기기인지 확인
   */
  static isMobileBrowser(): boolean {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i;
    const isMobile = regex.test(navigator?.userAgent);

    return isMobile;
  }

  /**
   * 문자열 포멧 설정
   *
   * (\u200b(숫자)\u200b) 형식을
   * (<a target="_blank" href="/booking?id=(숫자)&bookingStateCodeIn=">(숫자)</a>) 으로 변경
   *
   * @example (180501) -> (<a target="_blank" href="/booking?id=180501&bookingStateCodeIn=">180501</a>)
   */
  static getFormattedRemarks(text: string): string {
    if (!text) {
      return null!;
    }

    return text.replace(
      /\(\u200b(\d+)\u200b\)/g,
      `(<a target="_blank" href="/booking?id=$1&bookingStateCodeIn=">$1</a>)`,
    );
  }

  /**
   * json화 된 데이터 또는 폴백 값 반환
   */
  static getStringifiedJson(parsed: any, fallback = ''): string {
    if (!parsed) {
      return fallback;
    }

    try {
      return JSON.stringify(parsed);
    } catch (error) {
      return fallback;
    }
  }

  /**
   * 파싱 된 데이터 또는 폴백 값 반환
   */
  static getParsedJson(
    stringified: string | null | undefined,
    fallback: any = null,
  ): any {
    if (!stringified) {
      return fallback;
    }

    try {
      return JSON.parse(stringified);
    } catch (error) {
      return fallback;
    }
  }

  /**
   * 검색 조건 설정 여부 확인
   */
  static isSearchParamEmpty(params: any, name: string): boolean {
    const entries = Object.entries(params);

    // name 으로 된 목록 확인
    if (
      entries.length === 1 &&
      entries[0][0] === name &&
      !(<string[]>entries[0][1]).length
    ) {
      // 값 없음
      return true;
    }

    // 다른 조건 있음
    return false;
  }

  /**
   * 언어 접미사 붙은 데이터 키 반환
   * @param key 원본 데이터 키
   * @param language 언어
   */
  static getDataI18nKey(
    key: string,
    language: string,
    isKoreanNeedSuffix = false,
  ): string {
    if (language === 'ko' && !isKoreanNeedSuffix) {
      return key;
    }

    const lowerCase = language.toLowerCase();
    const hyphenSplitList = lowerCase.split('-');
    let firstCapitalized = '';

    hyphenSplitList.forEach((hyphenSplit) => {
      firstCapitalized += startCase(hyphenSplit);
    });

    return `${key}${firstCapitalized}`;
  }

  /**
   * 데이터에서 원하는 번역 반환
   * @param data 데이터
   * @param key 데이터 키
   * @param language 찾는 언어
   * @param fallback 찾는 값이 없을때 반환할 값 기본은 `data[key]`
   * @returns 찾는 값 없으면 `fallback` 반환
   */
  static getDataI18n(
    data: any,
    key: string,
    language: string,
    fallback = data[key],
  ): any {
    const keyName = Utils.getDataI18nKey(key, language, true);
    return data[keyName] || fallback;
  }

  /**
   * 시작일부터 종료일 사이의 일자 목록 반환
   * @param fromDate 시작일
   * @param toDate 종료일
   * @param unit 시간 단위 day: 일별, month: 월별, year: 연도별
   * @returns 일자 목록
   */
  static getDateList(
    fromDate: string | Date | Dayjs,
    toDate: string | Date | Dayjs,
    unit: 'day' | 'month' | 'year' = 'day',
  ): string[] {
    // 년월일만 비교하기 위해 나머지는 0으로 설정
    const from = dayjs(fromDate).startOf(unit);
    const to = dayjs(toDate).startOf(unit);
    // from이 미래인 경우
    const dayDiff = Math.abs(to.diff(from, unit));

    // from 또는 to가 정상적인 날짜가 아니라면 NaN
    if (Number.isNaN(dayDiff)) {
      console.error('Invalid date');
      return [];
    }

    // from과 to가 같다면
    if (from.isSame(to)) {
      return [from.format(DATE_FORMAT)];
    }

    const dateList: string[] = [];
    // from이 과거이면 1, 미래이면 -1
    const direction = from.isBefore(to) ? 1 : -1;

    // 반복하며 일자 목록 추가
    for (let i = 0; i <= dayDiff; i += 1) {
      // from이 과거이면 덧셈, 미래이면 뺄셈
      dateList.push(from.add(i * direction, unit).format(DATE_FORMAT));
    }

    return dateList;
  }

  static getActivatedRouteSnapshotLastChild(
    snapshot: ActivatedRouteSnapshot,
  ): ActivatedRouteSnapshot {
    if (snapshot?.firstChild) {
      return this.getActivatedRouteSnapshotLastChild(snapshot.firstChild);
    }

    return snapshot;
  }

  /**
   * 하이픈 포함 주민등록번호 13자리 획득
   */
  static getFullResidentNum(birthDt: string, residentNum: string): string {
    // 없으면 공백
    const first = birthDt ? dayjs(birthDt).format('YYMMDD') : '';
    const second = residentNum || '';

    // 둘 다 없으면 null
    if (first === '' && second === '') {
      return null!;
    }

    return `${first}-${second}`;
  }

  /**
   * 분 단위 시간을 LocalTime로 변환
   */
  static toNumberToLocalTime(time: number): string {
    const hour = Math.floor(+time / 60);
    const min = +time % 60;
    return `${hour.toString().padStart(2, '0')}:${min
      .toString()
      .padStart(2, '0')}:00`;
  }

  /**
   * LocalTime을 분 단위 시간으로 변환
   */
  static toLocalTimeToNumber(localTime: string): number {
    if (!localTime) {
      return 0;
    }
    const split = localTime.split(':');
    const hour = +split[0];
    const minute = +split[1];
    const time = hour * 60 + minute;
    return !Number.isNaN(time) ? time : 0;
  }

  /**
   * HTML 도큐먼트 프로퍼티 조회
   *
   * CSS변수, 문서 폰트 크기 등 조회
   */
  static getPropertyValue(property: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(
      property,
    );
  }

  /**
   * Html body의 폰트 크기 획득
   */
  static geBodyFontSize(): string {
    return this.getPropertyValue('font-size');
  }

  /**
   * 뷰포트 크기 조회
   * @param pxSize 숫자 타입의 픽셀 크기
   * @param cssVarPrefix css 접두사(기본: bs)
   * @returns 뷰포트 크기 xs ~ xxl
   */
  static getViewportSize(pxSize: number, cssVarPrefix = 'bs'): ViewportSize {
    if (
      pxSize >=
      parseFloat(Utils.getPropertyValue(`--${cssVarPrefix}-breakpoint-xxl`))
    ) {
      // >= 1400px
      return 'xxl';
    }

    if (
      pxSize >=
      parseFloat(Utils.getPropertyValue(`--${cssVarPrefix}-breakpoint-xl`))
    ) {
      // >= 1200px
      return 'xl';
    }

    if (
      pxSize >=
      parseFloat(Utils.getPropertyValue(`--${cssVarPrefix}-breakpoint-lg`))
    ) {
      // >= 992px
      return 'lg';
    }

    if (
      pxSize >=
      parseFloat(Utils.getPropertyValue(`--${cssVarPrefix}-breakpoint-md`))
    ) {
      // >= 768px
      return 'md';
    }

    if (
      pxSize >=
      parseFloat(Utils.getPropertyValue(`--${cssVarPrefix}-breakpoint-sm`))
    ) {
      // >= 576px
      return 'sm';
    }

    // < 576px
    return 'xs';
  }
}
