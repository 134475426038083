import { Component, ElementRef, OnInit } from '@angular/core';
import { IHotelGoodsListDto } from 'src/lib/repository/hotel-goods/hotel-goods.model';
import { DialogService } from '../../services/dialog.service';
import { DialogRef } from '../dialog-ref';
import { DialogAbstract } from '../dialog.abstract';

export interface IRoomNumberSelectorData {
  hotelGoods: IHotelGoodsListDto;
  tourNumber: number;
  // calendarInfoList: ICalendarInfo[];
}

export interface IRoomNumberSelectorResult {
  sglNumber: number;
  twnNumber: number;
  trpNumber: number;
  quaNumber: number;
  etcNumber: number;
}

type RoomNumber =
  | 'sglNumber'
  | 'twnNumber'
  | 'trpNumber'
  | 'qudNumber'
  | 'etcNumber';

@Component({
  selector: 'app-room-number-selector',
  templateUrl: './room-number-selector.component.html',
  styleUrls: ['./room-number-selector.component.scss'],
})
export class RoomNumberSelectorComponent
  extends DialogAbstract
  implements OnInit
{
  override dialogRef!: DialogRef<
    this,
    IRoomNumberSelectorResult,
    IRoomNumberSelectorData
  >;

  readonly tourNumberMaxMessage = '룸 타입은 인원수를 초과할 수 없습니다';

  sglNumber = 0;

  twnNumber = 0;

  trpNumber = 0;

  qudNumber = 0;

  etcNumber = 0;

  sglMax = 0;

  twnMax = 0;

  trpMax = 0;

  qudMax = 0;

  etcMax = 0;

  get total(): number {
    // etc
    const { roomTypeEtcAvailMaxPerson, roomTypeEtcAvailMinPerson } =
      this.dialogRef.data!.hotelGoods;
    // 인원 합
    const total =
      this.sglNumber * 1 +
      this.twnNumber * 2 +
      this.trpNumber * 3 +
      this.qudNumber * 4 +
      this.etcNumber *
        (roomTypeEtcAvailMinPerson || roomTypeEtcAvailMaxPerson || 0);

    return total;
  }

  get canOk(): boolean {
    return this.dialogRef.data!.tourNumber <= this.total;
  }

  constructor(
    protected override elementRef: ElementRef<HTMLElement>,
    private dialogService: DialogService,
  ) {
    super(elementRef);
  }

  override ngOnInit(): void {
    this.sglMax = Math.max(
      this.sglMax,
      this.dialogRef.data!.hotelGoods.roomTypeSglAvailMaxPerson!,
    );
    this.twnMax = Math.max(
      this.twnMax,
      this.dialogRef.data!.hotelGoods.roomTypeTwnAvailMaxPerson!,
    );
    this.trpMax = Math.max(
      this.trpMax,
      this.dialogRef.data!.hotelGoods.roomTypeTrpAvailMaxPerson!,
    );
    this.qudMax = Math.max(
      this.qudMax,
      this.dialogRef.data!.hotelGoods.roomTypeQudAvailMaxPerson!,
    );
    this.etcMax = Math.max(
      this.etcMax,
      this.dialogRef.data!.hotelGoods.roomTypeEtcAvailMaxPerson!,
    );

    this.setRoomTypeNumber(this.dialogRef.data!.hotelGoods);

    super.ngOnInit();
  }

  onOkButtonClick(): void {
    // if (this.validateRoomNumber()) {
    //   this.dialogService.alert('룸 갯수는 예약 인원수를 초과할 수 없습니다.');
    //   return;
    // }

    this.dialogRef.close({
      sglNumber: this.sglNumber,
      twnNumber: this.twnNumber,
      trpNumber: this.trpNumber,
      quaNumber: this.qudNumber,
      etcNumber: this.etcNumber,
    });
  }

  onRoomNumberUpdate(update: number, type: string): void {
    if (
      update === 1 &&
      (this[
        `${type.toLowerCase()}Number` as keyof RoomNumberSelectorComponent
      ] as number) < 1
    ) {
      const message = this.dialogRef.data!.hotelGoods[
        `roomType${type}Message` as keyof IHotelGoodsListDto
      ] as string;

      if (message) {
        this.dialogService.alert(message).subscribe();
      }
    }

    this[`${type.toLowerCase()}Number` as RoomNumber] = update;
  }

  getMax(type: string): number {
    let max = 0;

    if (type === 'qud') {
      const total = this.total - this.qudNumber * 4;
      max = Math.floor((this.dialogRef.data!.tourNumber - total) / 4);
    } else if (type === 'trp') {
      const total = this.total - this.trpNumber * 3;
      max = Math.floor((this.dialogRef.data!.tourNumber - total) / 3);
    } else if (type === 'twn') {
      const total = this.total - this.twnNumber * 2;
      max = Math.floor((this.dialogRef.data!.tourNumber - total) / 2);
    } else {
      const total = this.total - this.sglNumber;
      max = this.dialogRef.data!.tourNumber - total;
    }

    return Math.max(max, 0);
  }

  private validateRoomNumber(): boolean {
    let totalRoomNumber = 0;

    if (this.etcNumber > 0) {
      totalRoomNumber += this.etcNumber;
    }

    if (this.qudNumber > 0) {
      totalRoomNumber += this.qudNumber * 4;
    }

    if (this.trpNumber > 0) {
      totalRoomNumber += this.trpNumber * 3;
    }

    if (this.twnNumber > 0) {
      totalRoomNumber += this.twnNumber * 2;
    }

    if (this.sglNumber > 0) {
      totalRoomNumber += this.sglNumber;
    }

    return totalRoomNumber <= this.dialogRef.data!.tourNumber;
  }

  /**
   * 룸타입별 개수 설정
   */
  private setRoomTypeNumber(hotelGoods: IHotelGoodsListDto): void {
    const {
      roomTypeByTourNumberFlg,
      roomTypeSglFlg,
      roomTypeTwnFlg,
      roomTypeTrpFlg,
      roomTypeQudFlg,
      roomTypeEtcFlg,
      roomTypeEtcAvailMinPerson,
      roomTypeQudAvailMinPerson,
      roomTypeSglAvailMinPerson,
      roomTypeTrpAvailMinPerson,
      roomTypeTwnAvailMinPerson,
      roomTypeEtcAvailMaxPerson,
      roomTypeQudAvailMaxPerson,
      roomTypeSglAvailMaxPerson,
      roomTypeTrpAvailMaxPerson,
      roomTypeTwnAvailMaxPerson,
    } = hotelGoods;
    // 룸 타입별 사용 여부 체크 한 개수
    const positiveNumber =
      roomTypeSglFlg! +
      roomTypeTwnFlg! +
      roomTypeTrpFlg! +
      roomTypeQudFlg! +
      roomTypeEtcFlg!;

    if (
      // 룸 타입별 관리 하지 않거나
      !roomTypeByTourNumberFlg ||
      // 하나도 체크하지 않았다면
      positiveNumber === 0
    ) {
      // 종료
      return;
    }

    // 1개보다 많으면
    if (positiveNumber > 1) {
      // 종료
      return;
    }

    // 키 이름
    let keyName:
      | keyof Omit<IRoomNumberSelectorResult, 'quaNumber'>
      | 'qudNumber'
      | undefined;
    // 기본 인원
    let defaultPerson: number = 0;
    // 최소 인원
    let availMinPerson: number = 0;
    // 최대 인원
    let availMaxPerson: number = 0;

    if (roomTypeSglFlg === 1) {
      defaultPerson = 1;
      availMinPerson = roomTypeSglAvailMinPerson || 1;
      availMaxPerson = roomTypeSglAvailMaxPerson || defaultPerson;
      keyName = 'sglNumber';
    } else if (roomTypeTwnFlg === 1) {
      defaultPerson = 2;
      availMinPerson = roomTypeTwnAvailMinPerson || 1;
      availMaxPerson = roomTypeTwnAvailMaxPerson || defaultPerson;
      keyName = 'twnNumber';
    } else if (roomTypeTrpFlg === 1) {
      defaultPerson = 3;
      availMinPerson = roomTypeTrpAvailMinPerson || 1;
      availMaxPerson = roomTypeTrpAvailMaxPerson || defaultPerson;
      keyName = 'trpNumber';
    } else if (roomTypeQudFlg === 1) {
      defaultPerson = 4;
      availMinPerson = roomTypeQudAvailMinPerson || 1;
      availMaxPerson = roomTypeQudAvailMaxPerson || defaultPerson;
      // quaNumber 아니므로 주의
      keyName = 'qudNumber';
    } else if (roomTypeEtcFlg === 1) {
      // 기본은 2인실로 판단
      defaultPerson = roomTypeEtcAvailMaxPerson || 2;
      availMinPerson = roomTypeEtcAvailMinPerson || 1;
      availMaxPerson = roomTypeEtcAvailMaxPerson || defaultPerson;
      keyName = 'etcNumber';
    }

    // 최소 최대가 0이 아니고 같다면
    if (availMinPerson === availMaxPerson && availMaxPerson !== 0) {
      // 인원수와 딱 맞아 떨어지면
      if (this.dialogRef.data!.tourNumber % availMinPerson === 0) {
        // 객실 수 초기값 입력
        this[keyName!] = this.dialogRef.data!.tourNumber / availMinPerson;
        // 종료
        return;
      }
    }

    // 최소 필요 객실
    const minRequiredRoom = Math.ceil(
      this.dialogRef.data!.tourNumber / availMaxPerson,
    );
    // 최대 필요 객실
    const maxRequiredRoom = Math.ceil(
      this.dialogRef.data!.tourNumber / availMinPerson,
    );
    // 최소 수용 인원
    const minCapacity = minRequiredRoom * availMinPerson;
    // 최대 수용 인원
    const maxCapacity = maxRequiredRoom * availMaxPerson;
    // 기본 필요 객실
    const defaultRequiredRoom = Math.ceil(
      this.dialogRef.data!.tourNumber / defaultPerson,
    );

    // 객실 인원수 제한에 부합하면
    if (
      this.dialogRef.data!.tourNumber >= minCapacity &&
      this.dialogRef.data!.tourNumber <= maxCapacity
    ) {
      // 객실 수 초기값 입력
      this[keyName!] = defaultRequiredRoom;
      // 종료
      return;
    }

    // ETC 타입이면
    if (keyName === 'etcNumber') {
      // 판단 불가하므로 종료
      return;
    }

    this.dialogService
      // 메시지 표시 후
      .alert(
        `현재 인원수로 이용할 수 없는 객실입니다.\n이용 가능 인원 : 최소 ${availMinPerson}, 최대 ${availMaxPerson} (인)`,
      )
      .subscribe();

    this.dialogRef.isBlockShowing = true;
    this.dialogRef.close(null!);
  }
}
