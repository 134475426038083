/* eslint-disable no-underscore-dangle */
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  timer,
} from 'rxjs';
import { finalize, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GlobalRequestHandler } from './global-request-handler';
import { IHalPageResponse, IPage, IPageResponse } from './page.model';

/**
 * API 서버 통신 처리 공통화.
 *
 * T : Entity 타입
 * T2 : API 응답 형태(HAL, Spring)
 */
export abstract class PageRepositoryService<
  T extends Record<string, any> = any,
  T2 extends IHalPageResponse | IPageResponse = IHalPageResponse,
> {
  /**
   * 현재 검색조건
   */
  recentSearchQuery: any = {};

  isListLoading: boolean = false;

  isDetailLoading: boolean = false;

  /**
   * 서버 URL
   * @example `environment.serverUrl`
   */
  protected apiServerUrl = environment.apiServerUrl;

  /**
   * 엔드포인트
   * @example 'oauth/token'
   */
  protected baseUri = '';

  /** api 엔티티(T)의 기본키 */
  protected pk = 'id';

  protected listSubject = new BehaviorSubject<IPage<T> | null>(null);

  protected optionsSubject = new BehaviorSubject<T[] | null>(null);

  // 조회 상태 유지 위한 변수
  private page: IPage<T> | null = null;

  private options: T[] | null = null;

  /**
   * 현재 조건 해당 목록
   *
   * getPage(단 건), appendPage(누적) 결과
   */
  get list$(): Observable<IPage<T> | null> {
    return this.listSubject.asObservable();
  }

  /**
   * 옵션 선택을 위한 전체 목록
   *
   * TODO: 최적화 필요
   */
  get options$(): Observable<T[] | null> {
    return this.optionsSubject.asObservable();
  }

  get isLoading(): boolean {
    return this.isListLoading || this.isDetailLoading;
  }

  // 조회 상태 유지 위한 변수

  constructor(protected http: HttpClient) {
    this.initList();
  }

  create(data: T, opt?: { completeMessage?: string }): Observable<T> {
    this.isDetailLoading = true;
    return this.http.post<T>(`${this.apiServerUrl}/${this.baseUri}`, data).pipe(
      map((res) => {
        const keys = Object.keys(res);
        if (keys.length === 1 && keys[0] === 'content') {
          // TODO: 차후 res.content 로 변경
          return res[keys[0]];
        }

        return res;
      }),
      GlobalRequestHandler.onAfterCreate$(opt?.completeMessage),
      GlobalRequestHandler.onRequestError$(),
      finalize(() => {
        this.isDetailLoading = false;
      }),
    );
  }

  /**
   * 검색조건에 일치하는 목록 조회 api 호출하여 페이지 데이터 조회
   *
   * getPage 와 기능 다름에 유의
   */
  findPage(params: any = {}): Observable<IPage<T>> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<T2>(`${this.apiServerUrl}/${this.baseUri}`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        map((res) => this.parsePage(res)),
        this.retryUncompleteError(),
      );
  }

  /**
   * 페이지를 요청하고, 현재 목록에 페이지를 추가 한다.
   *
   * TODO: 전페이지 조회, 현재 페이지 조회 중간에 추가된 아이템이 있다면 중복 처리 해야 함
   */
  appendPage(query = this.recentSearchQuery): void {
    this.isListLoading = true;

    this.findPage(query).subscribe({
      next: (res) => {
        // 성공했다면 최근 조건으로 set
        this.recentSearchQuery = query;

        if (this.page) {
          // TODO: 중복 처리 필요
          this.page.content.push(...res.content);
          this.page.page = res.page;
        }

        this.listSubject.next(this.page);
      },
      error: (error) => {
        this.isListLoading = false;
        this.initList();
        this.listSubject.error(error);
      },
    });
  }

  findItem(id: number): Observable<T> {
    this.isDetailLoading = true;
    return this.http.get<T>(`${this.apiServerUrl}/${this.baseUri}/${id}`).pipe(
      map((res) => {
        const keys = Object.keys(res);
        if (keys.length === 1 && keys[0] === 'content') {
          // TODO: 차후 res.content 로 변경
          return res[keys[0]];
        }

        return res;
      }),
      finalize(() => {
        this.isDetailLoading = false;
      }),
      this.retryUncompleteError(),
    );
  }

  update(id: any, item: T, opt?: { completeMessage?: string }): Observable<T> {
    this.isDetailLoading = true;
    return this.http
      .put<T>(`${this.apiServerUrl}/${this.baseUri}/${id}`, item)
      .pipe(
        map((res) => {
          // 결과 값 없을 수 있음
          if (res) {
            const keys = Object.keys(res);
            if (keys.length === 1 && keys[0] === 'content') {
              // TODO: 차후 res.content 로 변경
              return res[keys[0]];
            }
          }

          return res;
        }),
        GlobalRequestHandler.onAfterUpdate$(opt?.completeMessage),
        GlobalRequestHandler.onRequestError$(),
        finalize(() => {
          this.isDetailLoading = false;
        }),
      );
  }

  delete(id: number, opt?: { completeMessage?: string }): Observable<any> {
    this.isDetailLoading = true;
    return this.http.delete(`${this.apiServerUrl}/${this.baseUri}/${id}`).pipe(
      GlobalRequestHandler.onAfterDelete$(opt?.completeMessage),
      GlobalRequestHandler.onRequestError$(),
      finalize(() => {
        this.isDetailLoading = false;
      }),
    );
  }

  /**
   * 조건에 해당하는 페이지를 요청한다
   */
  getPage(query = this.recentSearchQuery): void {
    this.isListLoading = true;

    this.findPage(query).subscribe({
      next: (res) => {
        // 성공했다면 최근 조건으로 set
        this.recentSearchQuery = query;

        if (this.page) {
          this.page.content = res.content;
          this.page.page = res.page;
        }

        this.listSubject.next(this.page);
      },
      error: (error) => {
        this.isListLoading = false;
        this.initList();
        this.listSubject.error(error);
      },
    });
  }

  getOptions(forceRefresh = true, params = {}): void {
    let httpParams = this.makeObjToHttpParams(params);
    httpParams = httpParams.set('size', 1000);

    if (forceRefresh) {
      this.http
        .get<T2>(`${this.apiServerUrl}/${this.baseUri}`, {
          params: httpParams,
        })
        .subscribe({
          next: (res) => {
            this.options = this.parsePage(res).content;
            this.optionsSubject.next(this.options);
          },
          error: (error) => {
            this.options = null;
            this.optionsSubject.error(error);
          },
        });
    }
  }

  /**
   * 현재 목록 초기화
   *
   * 최초 사용, 요청 오류 시 등에 사용
   */
  initList(): void {
    this.page = {
      content: [],
      page: null,
    };

    // 검색 조건 초기화
    this.recentSearchQuery = {};

    this.listSubject.next(this.page);
  }

  /**
   * 호출받았을 때 객체를 http요청에 사용하도록 타입 변경
   *
   * 빈 값 삭제
   */
  protected makeObjToHttpParams(obj: any): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(obj).forEach((key) => {
      if (!key) {
        return;
      }
      if (
        obj[key] !== null &&
        obj[key] !== undefined &&
        obj[key] !== 'null' &&
        obj[key] !== ''
      ) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((value: any) => {
            if (value != null && value !== undefined) {
              httpParams = httpParams.appendAll({ [key]: value });
            }
          });
        } else {
          httpParams = httpParams.set(key, obj[key]);
        }
      }
    });
    return httpParams;
  }

  /**
   * 서버에서 응답받은 페이지 형식을 클라이언트 처리 용이하게 변경
   */
  protected parsePage(serverResponse: T2): IPage<T> {
    if (!('page' in serverResponse)) {
      // T2의 형식이 IPageResponse 이면
      const { content, size, totalElements, totalPages, number } =
        serverResponse as IPageResponse;

      return {
        content,
        page: {
          size,
          totalElements,
          totalPages,
          number,
        },
      };
    }

    // content 가 없으면 T2의 형식이 IHalPageResponse 로 간주
    const { _links, _embedded, content, page } =
      serverResponse as IHalPageResponse;

    return {
      content:
        (_embedded && _embedded[Object.keys(_embedded)[0]]) ?? content ?? [],
      page,
    };
  }

  /**
   * 통신 미완료 오류 재시도
   */
  protected retryUncompleteError<R>(
    retryCount = 2,
  ): MonoTypeOperatorFunction<R> {
    return retry({
      count: retryCount,
      delay: (e: HttpErrorResponse, count) => {
        if (e.status === 0) {
          // 완료되지 않은 응답은 (2초 * 재시도 수) 후 재시도
          return timer(2000 * count);
        }

        throw new Error(this.getErrorMessages(e), { cause: e });
      },
    });
  }

  /**
   * 내트워크 오류 메시지 가공
   */
  private getErrorMessages({ status, error }: HttpErrorResponse): string {
    if (error?.message) {
      return `[${error.code}] ${error.message}`;
    }

    if (status === 400) {
      // api 서버에서 badRequest 응답을 받았을 때. 예로 현 golftour 프로젝트에서 ExceptionHandler 없이 error 를 응답하는 경우 등
      const e: {
        content: any;
      } = error;
      if (e.content) {
        const m = e.content
          .map((err: any) => `${err.objectName} : ${err.defaultMessage}`)
          .join('\n');
        return `${m}`;
      }
    }

    if (status === 500) {
      const e: {
        status: number;
        message: string;
      } = error;
      return `[${e.status}]\n${e.message}`;
    }
    return `Network Error (${status})`;
  }
}
