import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DialogService } from '../../services/dialog.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-tour-number',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './tour-number.component.html',
  styleUrls: ['./tour-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => TourNumberComponent),
    },
  ],
})
export class TourNumberComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  onTouched?: () => void;

  onChange?: (value: any) => void;

  @Input() dataBeforeChange: any;

  @Input() disabled?: boolean;

  @Input() showSuffix = true;

  @Input() suffix = 'peopleCntUnit';

  @Input() hasLabel = true;

  @Input() canSelectZero = false;

  /**
   * 초과 선택 시도 시 표시할 메시지
   */
  @Input() maxMessage?: string;

  /**
   * 최대
   */
  @Input() max?: number;

  /**
   * 최소
   */
  @Input() min?: number;

  @Input()
  set tourNumber(n: number) {
    if (this.dataBeforeChange && this.dataBeforeChange.tourNumber < n) {
      this.dialogService.alert('VALID.cantIncreaseTourNumber').subscribe();
      return;
    }

    if (this.#tourNumber !== n) {
      this.#tourNumber = n;
      this.onChange?.(n);
    }
  }

  get tourNumber(): number {
    return this.#tourNumber;
  }

  #tourNumber = 0;

  get isApp(): boolean {
    return this.uiService.isMobile;
  }

  device$: Observable<string>;

  constructor(
    private dialogService: DialogService,
    private uiService: UiService,
  ) {
    this.device$ = this.uiService.device$;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // 최대 인원 변경됐으면
    if (changes['max']) {
      // 유효성 검사
      this.setTourNumber(0);
    }
  }

  writeValue(obj: any): void {
    this.#tourNumber = obj;
    // 유효성 검사 하기 위해 호출
    this.setTourNumber(0);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // 인원수 컨트롤 제어
  setTourNumber(n: number): void {
    if (this.disabled) {
      return;
    }

    // 0 이하로 변경 시도시
    if (this.tourNumber + n <= 0) {
      // 설정에 따라 0 또는 1
      this.tourNumber = this.canSelectZero ? 0 : 1;
      return;
    }

    if (this.max && this.tourNumber + n > this.max) {
      if (this.maxMessage) {
        this.dialogService.alert(this.maxMessage).subscribe();
      }

      this.tourNumber = this.max;
      return;
    }

    if (this.min && this.tourNumber + n < this.min) {
      this.tourNumber = this.min;
      return;
    }

    this.tourNumber += n;
  }
}
