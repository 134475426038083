import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withJsonpSupport,
} from '@angular/common/http';
import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
} from '@angular/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { authInterceptor } from 'src/lib/auth/auth.interceptor-fn';
import {
  BRAND_IGNORE_PATH_LIST,
  brandInterceptor,
} from 'src/lib/interceptor/brand.interceptor';
import { loggingInterceptor } from 'src/lib/interceptor/logging.interceptor-fn';
import {
  PW_UPDATE_OPTION,
  PwUpdateOption,
} from 'src/lib/services/app-update.service';
import { PW_STORAGE_PREFIX } from 'src/lib/services/storage.service';
import { TranslateVersionLoader } from 'src/lib/translate-version-loader';
import { TemplateLayoutModule } from './components/template-layout/template-layout.module';

import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeKo from '@angular/common/locales/ko';
import {
  DIALOG_SERVICE,
  LOGIN_MODAL_COMPONENT,
} from 'src/lib/guards/login.guard';
import { register as registerSwiper } from 'swiper/element';
import { BootstrapAppService } from '../lib/services/bootstrap-app.service';
import { BootstrapService } from '../lib/services/bootstrap.service';
import { ToastAppService } from '../lib/services/toast-app.service';
import { ToastService } from '../lib/services/toast.service';
import { routes } from './app.routes';
import { LoginComponent } from './components/dialog/login/login.component';
import { DialogAppService } from './services/dialog-app.service';
import { DialogService } from './services/dialog.service';
import { BookingPackagegoodsStateItem } from './states/booking-packagegoods-list.state-item';
import { HomeLayoutGoodsStateItem } from './states/home-layout-goods.state-item';
import { HomeLayoutMrhstStateItem } from './states/home-layout-mrhst.state-item';
import { HomeLayoutPackagegoodsStateItem } from './states/home-layout-packagegoods.state-item';
import { HomeLayoutTemairazuMrhstStateItem } from './states/home-layout-temairazu-mrhst.state-item';

registerSwiper();

registerLocaleData(localeKo);

const pwUpdateOption: PwUpdateOption = {
  messageTranslateKey: 'updateMessage',
  actionTranslateKey: 'updateAction',
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(
      routes,
      withInMemoryScrolling({
        get scrollPositionRestoration(): 'disabled' | 'enabled' | 'top' {
          return window.history.scrollRestoration === 'manual'
            ? 'enabled'
            : 'disabled';
        },
      }),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideHttpClient(
      withJsonpSupport(),
      withInterceptors([loggingInterceptor, authInterceptor, brandInterceptor]),
    ),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (httpClient: HttpClient) =>
            new TranslateVersionLoader(httpClient, ['ko', 'en', 'ja']),
          deps: [HttpClient],
        },
        useDefaultLang: false,
      }),
      TemplateLayoutModule,
    ]),
    { provide: BRAND_IGNORE_PATH_LIST, useValue: [] },
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    // TODO: 추후 직접 변경 가능하게 개선 필요
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'KRW' },
    // LocalStorage 설정
    { provide: PW_STORAGE_PREFIX, useValue: 'TOURNITY' },
    { provide: LOGIN_MODAL_COMPONENT, useValue: LoginComponent },
    { provide: DIALOG_SERVICE, useValue: DialogService },
    // {
    //   provide: MAT_DATE_LOCALE,
    //   useFactory: (lang: LanguageService) => {
    //     return lang.lang;
    //   },
    //   deps: [LanguageService],
    // },
    { provide: PW_UPDATE_OPTION, useValue: pwUpdateOption },
    {
      provide: DialogService,
      useClass: DialogAppService,
    },
    {
      provide: ToastService,
      useClass: ToastAppService,
      deps: [DOCUMENT, TranslateService],
    },
    {
      provide: BootstrapService,
      useClass: BootstrapAppService,
    },
    HomeLayoutPackagegoodsStateItem,
    HomeLayoutMrhstStateItem,
    HomeLayoutTemairazuMrhstStateItem,
    HomeLayoutGoodsStateItem,
    BookingPackagegoodsStateItem,
  ],
};
