import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { GlobalRequestHandler } from '../global-request-handler';
import { ICarGoods, ICarGoodsSearchKeyword } from './car-goods.model';

@Injectable({
  providedIn: 'root',
})
export class CarGoodsApi extends PageRepositoryService<ICarGoods> {
  override baseUri = 'api/carGoods';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  batchInsert(body: any, params: any): Observable<any> {
    this.isDetailLoading = true;
    return this.http
      .post<ICarGoods>(`${this.apiServerUrl}/${this.baseUri}/list`, body, {
        params,
      })
      .pipe(
        GlobalRequestHandler.onAfterCreate$(),
        GlobalRequestHandler.onRequestError$(),
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  getHomepageList(params: any): Observable<ICarGoods[]> {
    return this.http.get<ICarGoods[]>(
      `${this.apiServerUrl}/${this.baseUri}/homepage`,
      { params },
    );
  }

  /**
   * 검색 키워드 조회
   */
  getSearchKeyword(params: any): Observable<ICarGoodsSearchKeyword[]> {
    return this.http.get<ICarGoodsSearchKeyword[]>(
      `${this.apiServerUrl}/${this.baseUri}/searchKeyword`,
      { params },
    );
  }
}
