import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PackagegoodsApi } from '../../lib/repository/packagegoods/packagegoods.api';
import { IPackagegoods } from '../../lib/repository/packagegoods/packagegoods.model';
import { BrandService } from '../../lib/services/brand.service';
import { StateItem } from '../../lib/state-item';

@Injectable()
export class BookingPackagegoodsStateItem extends StateItem<IPackagegoods[]> {
  constructor(
    private packagegoodsApi: PackagegoodsApi,
    private brandService: BrandService,
  ) {
    super([]);
  }

  loadPackagegoods(): void {
    this.packagegoodsApi
      .findBookingList({
        brandId: this.brandService.brand.id,
        bookingFlg: 1,
        sort: 'sort',
      })
      .subscribe((list) => {
        this.setValue(list);
      });
  }

  get list$(): Observable<IPackagegoods[]> {
    return this.value$;
  }

  get list(): IPackagegoods[] {
    return this.value;
  }
}
