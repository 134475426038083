import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { IMenu } from './menu.model';

@Injectable({
  providedIn: 'root',
})
export class MenuApi extends PageRepositoryService<IMenu> {
  override baseUri = 'api/menu';

  #menuList: IMenu[] = [];

  get menuList(): IMenu[] {
    return this.#menuList;
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getMenu(homeScreenId: number): Observable<IMenu[]> {
    return this.findPage({ homeScreenId, size: 100, sort: 'sort,asc' }).pipe(
      map((page) => page.content),
      tap((list) => {
        this.#menuList = list;
      }),
    );
  }
}
