import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { BatchInsertable } from '../batch-insertable';
import { GlobalRequestHandler } from '../global-request-handler';
import {
  IRestaurantGoods,
  IRestaurantGoodsSearchKeyword,
} from './restaurant-goods.model';

@Injectable({
  providedIn: 'root',
})
export class RestaurantGoodsApi
  extends PageRepositoryService<IRestaurantGoods>
  implements BatchInsertable
{
  override baseUri = 'api/restaurantGoods';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  batchInsert(body: any, params: any): Observable<any> {
    this.isDetailLoading = true;
    return this.http
      .post<IRestaurantGoods>(
        `${this.apiServerUrl}/${this.baseUri}/list`,
        body,
        {
          params,
        },
      )
      .pipe(
        GlobalRequestHandler.onAfterCreate$(),
        GlobalRequestHandler.onRequestError$(),
        finalize(() => {
          this.isDetailLoading = false;
        }),
      );
  }

  getHomepageList(params: any): Observable<IRestaurantGoods[]> {
    return this.http.get<IRestaurantGoods[]>(
      `${this.apiServerUrl}/${this.baseUri}/homepage`,
      { params },
    );
  }

  /**
   * 검색 키워드 조회
   */
  getSearchKeyword(params: any): Observable<IRestaurantGoodsSearchKeyword[]> {
    return this.http.get<IRestaurantGoodsSearchKeyword[]>(
      `${this.apiServerUrl}/${this.baseUri}/searchKeyword`,
      { params },
    );
  }
}
