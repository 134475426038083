import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MrhstApi } from '../../lib/repository/mrhst/mrhst.api';
import { IMrhst } from '../../lib/repository/mrhst/mrhst.model';
import { StateItem } from '../../lib/state-item';

@Injectable()
export class HomeLayoutMrhstStateItem extends StateItem<{
  [key: string]: IMrhst[];
}> {
  constructor(private mrhstApi: MrhstApi) {
    super({});
  }

  loadMrhst(idIn: number[]): void {
    if (this.value[idIn.join('_')]) {
      return;
    }

    this.mrhstApi
      .findPage({
        idIn: idIn,
        sort: 'sort,asc',
      })
      .subscribe((page) => {
        this.setValue({ ...this.value, [idIn.join('_')]: page.content });
      });
  }

  getList$(idIn: number[]): Observable<IMrhst[]> {
    return this.value$.pipe(map((value) => value[idIn.join('_')]));
  }

  getList(idIn: number[]): IMrhst[] {
    return this.value[idIn.join('_')];
  }
}
