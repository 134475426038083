import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HomepageApi } from '../repository/home-screen/homepage.api';
import { BrandService } from '../services/brand.service';
import { PermissionService } from '../services/permission.service';

/**
 * 홈페이지 브랜드 가드
 */
export const homepageBrandGuard: CanActivateFn = () => {
  const homepageApi = inject(HomepageApi);
  const brandService = inject(BrandService);
  const permissionService = inject(PermissionService);

  return homepageApi.getBrand().pipe(
    tap((brand) => {
      brandService.init(brand);
      permissionService.init(brand);
    }),
    map(() => true),
    catchError(() => of(false)),
  );
};
