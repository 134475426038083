import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { CurrencyCd } from '../enums';
import { GlobalRequestHandler } from '../global-request-handler';
import {
  IUserCard,
  IUserCardChrgDto,
  IUserCardPrpayHistDto,
  IUserCardUseDto,
} from './user-card.model';

@Injectable({
  providedIn: 'root',
})
export class UserCardApi extends PageRepositoryService<IUserCard> {
  override baseUri = 'api/userCard';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  /**
   * 선불금 충전
   */
  chrg(id: number, body: IUserCardChrgDto): Observable<IUserCard> {
    return this.http
      .post<IUserCard>(`${this.apiServerUrl}/${this.baseUri}/${id}/chrg`, body)
      .pipe(
        GlobalRequestHandler.onAfterCreate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  /**
   * 선불금 사용(차감)
   */
  use(id: number, body: IUserCardUseDto): Observable<IUserCard> {
    return this.http
      .post<IUserCard>(`${this.apiServerUrl}/${this.baseUri}/${id}/use`, body)
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  /**
   * 선불금 환전
   */
  // exchange(): Observable<IUserCard> {
  //   return null;
  // }

  /**
   * 선불금 사용 내역 목록 조회
   * @param id 회원 카드 식별번호
   * @param currencyCd 통화 코드
   * @param fromDate 생성일 시작
   * @param toDate 생성일 종료
   * @returns 내역 목록
   */
  getPrpayHistList(
    id: number,
    currencyCd: CurrencyCd,
    fromDate: string,
    toDate: string,
  ): Observable<IUserCardPrpayHistDto[]> {
    const params = { currencyCd, fromDate, toDate };
    this.isListLoading = true;

    return this.http
      .get<IUserCardPrpayHistDto[]>(
        `${this.apiServerUrl}/${this.baseUri}/${id}/prpay`,
        {
          params,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }
}
