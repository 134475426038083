import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import {
  IBookingRoomInfo,
  ICalendarInfo,
  ICalendarInfoDisplay,
} from './calendar-info.model';

@Injectable({
  providedIn: 'root',
})
export class FrontApi extends PageRepositoryService<any> {
  override baseUri = 'api/front2';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getCalendarInfoList(params: any): Observable<ICalendarInfo[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<ICalendarInfo[]>(
        `${this.apiServerUrl}/${this.baseUri}/calendarinfo`,
        {
          params: httpParams,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
      );
  }

  getRoomInfoList(params: any): Observable<ICalendarInfoDisplay[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<ICalendarInfoDisplay[]>(
        `${this.apiServerUrl}/${this.baseUri}/roominfo`,
        {
          params: httpParams,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
      );
  }

  getRoomInfoAdminList(params: any): Observable<IBookingRoomInfo[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<IBookingRoomInfo[]>(
        `${this.apiServerUrl}/${this.baseUri}/roominfoAdmin`,
        {
          params: httpParams,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
      );
  }
}
