import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PageRepositoryService } from 'src/lib/repository/abstract-repository.service';
import {
  TemairazuInventoriesResponse,
  TemairazuPlanListResponse,
  TemairazuRoomListResponse,
  TmRoomplanSearchResponse,
} from './temairazu.model';

@Injectable({
  providedIn: 'root',
})
export class TmRoomplanApi extends PageRepositoryService<any> {
  override baseUri = `/pms/temairazu/roomplan`;

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getInventories(
    hotelId: number,
    params?: any,
  ): Observable<TemairazuInventoriesResponse> {
    return this.http
      .get<any>(`${this.apiServerUrl}${this.baseUri}/inventories/${hotelId}`, {
        params,
      })
      .pipe(
        map((res) => res),
        this.retryUncompleteError(1),
      );
  }

  getRoomplanSearch(
    hotelId: number,
    body?: any,
  ): Observable<TmRoomplanSearchResponse> {
    return this.http
      .post<any>(`${this.apiServerUrl}${this.baseUri}/search/${hotelId}`, {
        Conditions: body,
      })
      .pipe(
        map((res) => res),
        this.retryUncompleteError(1),
      );
  }

  getPrice(hotelId: number, body?: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiServerUrl}${this.baseUri}/getprice/${hotelId}`, {
        Plan: body,
      })
      .pipe(
        map((res) => res),
        this.retryUncompleteError(1),
      );
  }

  getRoomList(
    hotelId: number,
    params?: any,
  ): Observable<TemairazuRoomListResponse> {
    return this.http
      .get<any>(`${this.apiServerUrl}${this.baseUri}/room/${hotelId}`, {
        params,
      })
      .pipe(
        map((res) => res),
        this.retryUncompleteError(1),
      );
  }

  getPlanList(
    hotelId: number,
    params?: any,
  ): Observable<TemairazuPlanListResponse> {
    return this.http
      .get<any>(`${this.apiServerUrl}${this.baseUri}/plan/${hotelId}`, {
        params,
      })
      .pipe(
        map((res) => res),
        this.retryUncompleteError(1),
      );
  }
}
