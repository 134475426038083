import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { IGoods } from './goods.model';

@Injectable({
  providedIn: 'root',
})
export class GoodsApi extends PageRepositoryService<IGoods> {
  override baseUri = 'api/goods';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getGoodsByRegion(tourRegionCode?: string): Observable<any[]> {
    return this.findPage().pipe(
      map((page) => {
        const arr = page?.content;

        return tourRegionCode
          ? arr.filter((goods: any) => goods?.tourRegionCode === tourRegionCode)
          : arr;
      }),
    );
  }
}
