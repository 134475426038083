import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { MenuApi } from '../repository/menu/menu.api';
import { HomeScreenService } from '../services/home-screen.service';

/**
 * Menu Guard
 */
export const menuGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const location = inject(Location);
  const menuApi = inject(MenuApi);
  const homeScreenService = inject(HomeScreenService);

  return menuApi
    .getMenu(homeScreenService.homeScreen.id!)
    .pipe(
      map(() => true),
      catchError(() => of(true)),
    )
    .pipe(
      map(() => {
        const isMenuExist =
          menuApi.menuList.findIndex(
            (menu) => menu.id === +route.params['id'],
          ) !== -1;

        // 메뉴 존재하지 않으면 최상위로
        if (!isMenuExist) {
          return router.createUrlTree([]);
        }

        const segments =
          router.parseUrl(location.path(false)).root.children['primary']
            ?.segments ?? [];

        // 세그먼트 개수가 2개를 넘으면 타입을 임의로 지정한 것으로 판단
        // 임의로 타입을 지정해서 접근할 수 없도록 함
        // if (segments.length > 2) {
        //   // 경로 변경
        //   router.navigate(['menu', route.params.id]);
        // }

        return true;
      }),
    );
};
