import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { map, mergeMap, tap } from 'rxjs';
import { IBrand } from '../repository/brand/brand.model';
import { HomepageApi } from '../repository/home-screen/homepage.api';
import { MenuApi } from '../repository/menu/menu.api';
import { BrandService } from '../services/brand.service';
import { DynamicColorService } from '../services/dynamic-color.service';
import { HomeScreenService } from '../services/home-screen.service';
import { LanguageService } from '../services/language.service';

export const homepageMasterResolver: ResolveFn<boolean> = () => {
  const router = inject(Router);
  const location = inject(Location);
  const homepageApi = inject(HomepageApi);
  const menuApi = inject(MenuApi);
  const brandService = inject(BrandService);
  const languageService = inject(LanguageService);
  const homeScreenService = inject(HomeScreenService);
  const dynamicColorService = inject(DynamicColorService);

  const { queryParams } = router.parseUrl(location.path());

  const brand: IBrand = brandService.brand;

  return homepageApi.getHomeScreen(brand.id!).pipe(
    tap((homeScreen) => {
      // TODO: 구글 자동 번역 여부를 languageButtonVisible 이외의 설정으로 별도 관리할지 검토
      languageService.init(brand, homeScreen.languageButtonVisible);
      homeScreenService.init(homeScreen);
      // 스타일 색상 설정
      const primaryColor =
        queryParams?.['previewPrimaryColor'] ?? homeScreen?.primaryColor;

      const secondaryColor =
        queryParams?.['previewSecondaryColor'] ?? homeScreen?.secondaryColor;

      const tertiaryColor =
        queryParams?.['previewTertiaryColor'] ?? homeScreen?.tertiaryColor;

      const dangerColor =
        queryParams?.['previewDangerColor'] ?? homeScreen?.dangerColor;

      dynamicColorService.setPrimaryColor(primaryColor ?? '#145a9f');
      dynamicColorService.setSecondaryColor(secondaryColor ?? '#df144c');
      dynamicColorService.setTertiaryColor(tertiaryColor ?? '#f0b536');
      // 부트스트랩 기본 danger
      dynamicColorService.setDangerColor(dangerColor ?? '#dc3545');
    }),
    mergeMap((homeScreen) => {
      return menuApi.getMenu(homeScreen.id).pipe(map(() => true));
    }),
  );
};
