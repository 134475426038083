import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserClass } from '../../lib/repository/enums';
import { ICalendarInfoDisplay } from '../../lib/repository/front/calendar-info.model';
import { IHotelGoodsListDto } from '../../lib/repository/hotel-goods/hotel-goods.model';
import { IPackagegoods } from '../../lib/repository/packagegoods/packagegoods.model';

@Injectable({
  providedIn: 'root',
})
export class PackagegoodsStaticService {
  constructor() {}

  getAvailablePackagegoodsList(
    customerClass: UserClass,
    packagegoodsList: IPackagegoods[],
  ): IPackagegoods[] {
    if (
      customerClass === '다이아몬드' ||
      customerClass === '다이아몬드Ⅱ' ||
      customerClass === '골드' ||
      customerClass === 'EWRC이용권' ||
      customerClass === 'EWRCⅡ' ||
      customerClass === '특별'
    ) {
      return packagegoodsList.filter((packagegoods) => {
        return this.isAvailablePackagegoods(customerClass, packagegoods.id!);
      });
    }

    return packagegoodsList;
  }

  isAvailablePackagegoods(
    customerClass: UserClass,
    packageId: number,
  ): boolean {
    if (customerClass === '특별' || customerClass === '골드') {
      return packageId !== environment.packagegoodsId.kirara;
    }

    return true;
  }

  /**
   * 패키지별 이용 가능 호텔 상품 반환
   */
  getAvailableHotelGoods(
    tourNumber: number,
    packagegoodsId: number,
    calendarInfo: ICalendarInfoDisplay,
    hotelGoodsList: IHotelGoodsListDto[],
  ): IHotelGoodsListDto[] {
    let goodsIdList: number[] = [];

    switch (packagegoodsId) {
      case environment.packagegoodsId.yamanami:
        goodsIdList = this.getYamanamiHotelId(tourNumber, calendarInfo);
        break;
      case environment.packagegoodsId.kangi:
        goodsIdList = this.getKangiHotelId(tourNumber, calendarInfo);
        break;
      case environment.packagegoodsId.kirara:
        goodsIdList = this.getKiraraHotelId(tourNumber, calendarInfo);
        break;
      case environment.packagegoodsId.sizunoyado:
        goodsIdList = this.getSizunoyadoHotelId(tourNumber, calendarInfo);
        break;
      case environment.packagegoodsId.nagoya:
        goodsIdList = this.getNagoyaHotelId(tourNumber, calendarInfo);
        break;
      case environment.packagegoodsId.beppu:
        goodsIdList = this.getBeppuHotelId(tourNumber, calendarInfo);
        break;
      case environment.packagegoodsId.nagano:
        goodsIdList = this.getNaganoHotelId(tourNumber, calendarInfo);
        break;
      case environment.packagegoodsId.sirosato:
      case environment.packagegoodsId.ibarakiSirosatoFromIncheon:
      case environment.packagegoodsId.ibarakiSirosatoFromBusan:
        goodsIdList = this.getSirosatoHotelId(tourNumber, calendarInfo);
        break;
      case environment.packagegoodsId.hukurodanoki:
      case environment.packagegoodsId.hukurodanokiFromIncheon:
      case environment.packagegoodsId.hukurodanokiFromBusan:
        goodsIdList = this.getHukurodanokiHotelId(tourNumber, calendarInfo);
        break;
      // case environment.packagegoodsId.aomori:
      //   goodsId = this.getAomoriHotelId(calendarInfo);
      //   break;
    }

    return goodsIdList
      .map((goodsId) => hotelGoodsList.find(({ id }) => goodsId === id))
      .filter((goods) => !!goods) as IHotelGoodsListDto[];
    // return hotelGoodsList.filter(({ id }) => goodsIdList.includes(id!)); // '마감';
  }

  private getYamanamiHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    // 트윈
    const twn = 140;
    // 소형트윈
    const sTwn = twn + 60;

    // 외부숙박
    const out = sTwn + 30;

    const totalTourNumber = calendarInfo.tourNumberTotal + tourNumber;

    if (totalTourNumber >= 0 && totalTourNumber <= twn) {
      return [
        environment.hotelGoodsId.yamanami.twn,
        environment.hotelGoodsId.yamanami.sTwn,
        environment.hotelGoodsId.yamanami.out,
      ];
    } else if (totalTourNumber >= twn && totalTourNumber <= sTwn) {
      return [
        environment.hotelGoodsId.yamanami.sTwn,
        environment.hotelGoodsId.yamanami.out,
      ];
    } else if (totalTourNumber >= sTwn && totalTourNumber <= out) {
      return [environment.hotelGoodsId.yamanami.out];
    }

    return [];
  }

  private getKangiHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    const twn = 30;

    const totalTourNumber = calendarInfo.tourNumberTotal + tourNumber;

    if (totalTourNumber >= 0 && totalTourNumber <= twn) {
      return [environment.hotelGoodsId.kangi.twn];
    }

    return [];
  }

  private getKiraraHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    const kirara = 12;

    const totalTourNumber = calendarInfo.tourNumberTotal + tourNumber;

    if (totalTourNumber >= 0 && totalTourNumber <= kirara) {
      return [environment.hotelGoodsId.kirara.kirara];
    }

    return [];
  }

  private getSizunoyadoHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    const main = 8;
    const annex = 6;

    const total = main + annex;

    const totalTourNumber = calendarInfo.tourNumberTotal + tourNumber;

    if (totalTourNumber >= 0 && totalTourNumber <= total) {
      return [environment.hotelGoodsId.sizunoyado.sizunoyado];
    }

    return [];
  }

  private getNagoyaHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    const twn = 24;
    const karaban = twn + 18;
    const qud = karaban + 72;

    const totalTourNumber = calendarInfo.tourNumberTotal + tourNumber;

    if (totalTourNumber >= 0 && totalTourNumber <= twn) {
      return [
        environment.hotelGoodsId.nagoya.twn,
        environment.hotelGoodsId.nagoya.karaban,
        environment.hotelGoodsId.nagoya.qud,
      ];
    } else if (totalTourNumber >= twn && totalTourNumber <= karaban) {
      return [
        environment.hotelGoodsId.nagoya.karaban,
        environment.hotelGoodsId.nagoya.qud,
      ];
    } else if (totalTourNumber >= karaban && totalTourNumber <= qud) {
      return [environment.hotelGoodsId.nagoya.qud];
    }

    return [];
  }

  private getBeppuHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    const newWing = 12;
    const main = newWing + 18;

    const totalTourNumber = calendarInfo.tourNumberTotal + tourNumber;

    if (totalTourNumber >= 0 && totalTourNumber <= newWing) {
      return [
        environment.hotelGoodsId.beppu.new,
        environment.hotelGoodsId.beppu.twn,
      ];
    } else if (totalTourNumber >= newWing && totalTourNumber <= main) {
      return [environment.hotelGoodsId.beppu.twn];
    }

    return [];
  }

  private getNaganoHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    const twn = 70;

    const totalTourNumber = calendarInfo.tourNumberTotal + tourNumber;

    if (totalTourNumber >= 0 && totalTourNumber <= twn) {
      return [environment.hotelGoodsId.nagano.twn];
    }

    return [];
  }

  private getHukurodanokiHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    const twn = 40;

    const totalTourNumber = calendarInfo.tourNumberTotal + tourNumber;

    if (totalTourNumber >= 0 && totalTourNumber <= twn) {
      return [environment.hotelGoodsId.hukurodanoki.twn];
    }

    return [];
  }

  private getSirosatoHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    const twn = 40; // 62
    const qud = twn + 0; // 8

    const totalTourNumber = calendarInfo.tourNumberTotal + tourNumber;

    if (totalTourNumber >= 0 && totalTourNumber <= twn) {
      return [environment.hotelGoodsId.sirosato.twn];
    }
    // else if (totalTourNumber >= twn && totalTourNumber <= qud) {
    //   return [environment.hotelGoodsId.sirosato.qud];
    // }

    return [];
  }

  private getAomoriHotelId(
    tourNumber: number,
    calendarInfo: ICalendarInfoDisplay,
  ): number[] {
    return [];
  }
}
