import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PackagegoodsApi } from '../../lib/repository/packagegoods/packagegoods.api';
import { IPackagegoods } from '../../lib/repository/packagegoods/packagegoods.model';
import { StateItem } from '../../lib/state-item';

@Injectable()
export class HomeLayoutPackagegoodsStateItem extends StateItem<{
  [key: string]: IPackagegoods[];
}> {
  constructor(private packagegoodsApi: PackagegoodsApi) {
    super({});
  }

  loadPackagegoods(idIn: number[]): void {
    if (this.value[idIn.join('_')]) {
      return;
    }

    this.packagegoodsApi
      .findHomeList({
        goodsIdIn: idIn,
        sort: 'sort,asc',
      })
      .subscribe((list) => {
        this.setValue({ ...this.value, [idIn.join('_')]: list });
      });
  }

  getList$(idIn: number[]): Observable<IPackagegoods[]> {
    return this.value$.pipe(map((value) => value[idIn.join('_')]));
  }

  getList(idIn: number[]): IPackagegoods[] {
    return this.value[idIn.join('_')];
  }
}
