import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize, map } from 'rxjs';
import { CacheableRepositoryService } from '../abstract-cacheable-repository.service';
import { GoodsType } from '../enums';
import { IHalPageResponse, IPage } from '../page.model';
import {
  IPackagegoods,
  IPackagegoodsBookingCalendarDto,
  IPackagegoodsHomepage,
  IPackagegoodsSearchKeyword,
  PackagegoodsAvailMrhstDto,
  PackagegoodsFlightsDto,
} from './packagegoods.model';

@Injectable({
  providedIn: 'root',
})
export class PackagegoodsApi extends CacheableRepositoryService<IPackagegoods> {
  override baseUri = 'api/packagegoods';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  /**
   * 패키지 일괄 등록
   */
  batchInsert(body: any, params: any): Observable<IPackagegoods> {
    this.isDetailLoading = true;
    return this.http
      .post<IPackagegoods>(`${this.apiServerUrl}/${this.baseUri}/list`, body, {
        params,
      })
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 이용 가능 시설 목록
   */
  getAvailMrhstList(
    id: number,
    params: any,
  ): Observable<PackagegoodsAvailMrhstDto> {
    return this.http.get<PackagegoodsAvailMrhstDto>(
      `${this.apiServerUrl}/${this.baseUri}/${id}/availMrhst`,
      { params },
    );
  }

  /*
   * 선택한 패키지, 타입의 이용 가능 시설에 속한 상품 목록 조회
   * @param id 패키지 아이디
   * @param type 상품 타입
   * @returns 이용 가능 시설에 속한 상품 목록
   */
  getAvailGoodsList(id: number, goodsType: GoodsType): Observable<any> {
    const httpParams = this.makeObjToHttpParams({ goodsType });
    this.isListLoading = true;

    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/${id}/availGoods`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 패키지 검색 키워드 조회
   */
  getSearchKeyword(): Observable<IPackagegoodsSearchKeyword[]> {
    return this.http.get<IPackagegoodsSearchKeyword[]>(
      `${this.apiServerUrl}/${this.baseUri}/searchKeyword`,
      {},
    );
  }

  getFlightsList(id: number): Observable<PackagegoodsFlightsDto> {
    return this.http.get<PackagegoodsFlightsDto>(
      `${this.apiServerUrl}/${this.baseUri}/${id}/flights`,
    );
  }

  /**
   * 온라인 예약 달력 조회
   */
  getBookingCalendar(
    params: any,
  ): Observable<IPackagegoodsBookingCalendarDto[]> {
    return this.http.get<any>(
      `${this.apiServerUrl}/${this.baseUri}/bookingCalendar`,
      { params },
    );
  }

  /**
   * 홈화면, 메뉴용 패키지 목록 조회
   */
  findHomeListPage(params: any = {}): Observable<IPage<IPackagegoods>> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<IHalPageResponse>(
        `${this.apiServerUrl}/${this.baseUri}/homepage/home`,
        {
          params: httpParams,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        map((res) => this.parsePage(res)),
        this.retryUncompleteError(),
      );
  }

  /**
   * 홈화면, 메뉴용 패키지 목록 조회
   */
  findHomeList(params: any = {}): Observable<IPackagegoods[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<IPackagegoods[]>(
        `${this.apiServerUrl}/${this.baseUri}/homepage/home/list`,
        {
          params: httpParams,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 예약용 패키지 목록 조회
   */
  findBookingList(params: any = {}): Observable<IPackagegoods[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}/homepage/bookingList`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 패키지 상세
   */
  findItemHomepage(id: number): Observable<IPackagegoodsHomepage> {
    this.isDetailLoading = true;
    return this.http
      .get<IPackagegoodsHomepage>(
        `${this.apiServerUrl}/${this.baseUri}/homepage/detail/${id}`,
      )
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 패키지 상세 + 예약 가능 여부 체크
   */
  findItemHomepageBooking(id: number): Observable<IPackagegoodsHomepage> {
    this.isDetailLoading = true;
    return this.http
      .get<IPackagegoodsHomepage>(
        `${this.apiServerUrl}/${this.baseUri}/homepage/detail/${id}/booking`,
      )
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 패키지 예약 가능 여부 체크
   */
  findItemHomepageBookingCheck(id: number): Observable<void> {
    this.isDetailLoading = true;
    return this.http
      .get<void>(
        `${this.apiServerUrl}/${this.baseUri}/homepage/detail/${id}/booking/check`,
      )
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 패키지 상세
   */
  findItemHomepageSchedule(id: number): Observable<IPackagegoodsHomepage> {
    this.isDetailLoading = true;
    return this.http
      .get<IPackagegoodsHomepage>(
        `${this.apiServerUrl}/${this.baseUri}/homepage/detail/${id}/schedule`,
      )
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }
}
