import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Offcanvas } from 'bootstrap';
import { Observable, filter, of, tap } from 'rxjs';
import { AuthService } from 'src/lib/auth/auth.service';
import { IBrand } from 'src/lib/repository/brand/brand.model';
import { MenuApi } from 'src/lib/repository/menu/menu.api';
import { IUserCard } from 'src/lib/repository/user-card/user-card.model';
import { IUserInfo } from 'src/lib/repository/user-info/user-info.model';
import { BrandService } from 'src/lib/services/brand.service';
import { HomeScreenService } from 'src/lib/services/home-screen.service';
import { LanguageService } from 'src/lib/services/language.service';
import { environment } from '../../../../environments/environment';
import { DialogService } from '../../../services/dialog.service';
import { UiService } from '../../../services/ui.service';
import { LoginComponent } from '../../dialog/login/login.component';

export interface IHeaderTitleInfo {
  key: string;
  headerTitle: string;
  showHeader?: boolean;
  showSideMenu?: boolean;
  showBorder?: boolean;
}

@Component({
  selector: 'app-template-header',
  templateUrl: './template-header.component.html',
  styleUrls: ['./template-header.component.scss'],
})
export class TemplateHeaderComponent implements OnInit {
  // 메뉴 마스터 목록
  readonly menuListNew = this.menuApi.menuList;

  // 가입 가능 여부
  canRegister = this.homeScreenService.homeScreen.userSignUp;

  // 현재 언어
  lang?: string;

  /** 로그인 여부 */
  isLoggedIn?: boolean;

  // 언어 변경 가능 여부
  @Input() canChangeLanguage?: boolean = true;

  // FIXME: 방식 검토
  // TODO: config 에 추가
  #headerTitleList: IHeaderTitleInfo[] = [
    {
      key: 'notice',
      headerTitle: 'notice',
      showHeader: true,
      showBorder: true,
    },
    {
      key: ':ntcId',
      headerTitle: 'notice',
      showHeader: true,
      showBorder: false,
    },
    { key: 'qna', headerTitle: 'qnaPrivate', showHeader: true },
    { key: 'package/:id', headerTitle: 'Goods', showHeader: true },
    { key: 'package', headerTitle: 'goodsList', showHeader: true },
    // { key: '/mobile-my', headerTitle: 'myPage' },
    { key: 'change-info', headerTitle: 'changeUserInfo', showHeader: true },
    {
      key: 'prpay',
      headerTitle: 'Membership',
      showHeader: true,
      showSideMenu: false,
    },
    {
      key: 'booking/:id/payment',
      headerTitle: 'step2',
      showHeader: true,
      showSideMenu: true,
      showBorder: true,
    },
    {
      key: 'booking/:id/info',
      headerTitle: 'personParty',
      showHeader: true,
      showSideMenu: true,
      showBorder: true,
    },
    {
      key: 'booking/:id',
      headerTitle: 'Booking',
      showHeader: true,
      showSideMenu: true,
      showBorder: true,
    },
    {
      key: 'booking/',
      headerTitle: 'rsrv',
      showHeader: true,
      showSideMenu: true,
    },
    {
      key: 'booking',
      headerTitle: 'myRsrv',
      showHeader: true,
      showSideMenu: true,
    },
    {
      key: 'join',
      headerTitle: 'Register',
      showHeader: true,
      showSideMenu: false,
    },
    { key: 'facility', headerTitle: '', showHeader: false },

    {
      key: 'company-region/:region',
      headerTitle: '회원사 소개',
      showHeader: true,
    },
    {
      key: 'company-region',
      headerTitle: '회원사 나라별 소개',
      showHeader: true,
    },
    { key: 'company', headerTitle: '회원사 상세', showHeader: true },
  ];

  headerTitle?: string;

  headerType?: string;

  showUserClass = false;

  showBorder = true;

  showHeader = true;

  showSideMenu = true;

  headerTransparent = false;

  isScrollTop?: boolean;

  brand?: IBrand;

  isProd = environment.production;

  logoImgUrl?: string;

  /**
   * 회원 정보
   */
  @Input()
  userInfo: IUserInfo | null = null;

  /**
   * 포인트 조회용 회원 카드
   */
  @Input()
  userCard: IUserCard | null = null;

  @Output()
  refreshInfoClick: EventEmitter<void> = new EventEmitter<void>();

  protected languageList = [
    { code: 'ja', name: '日本語' },
    { code: 'ko', name: '한국어' },
    { code: 'en', name: 'English' },
    { code: 'zh-CN', name: '简体中文' },
    { code: 'zh-TW', name: '繁體中文' },
    { code: 'vi', name: 'Tiếng Việt' },
    { code: 'ne', name: 'नेपाली भाषा' },
    { code: 'id', name: 'Bahasa Indonesia' },
    { code: 'tl', name: 'Filipino' },
    { code: 'th', name: 'ภาษาไทย' },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private languageService: LanguageService,
    private location: Location,
    private router: Router,
    private dialogService: DialogService,
    private menuApi: MenuApi,
    private brandService: BrandService,
    private homeScreenService: HomeScreenService,
    private uiService: UiService,
  ) {}

  ngOnInit(): void {
    this.brand = this.brandService.brand;

    this.headerType = this.homeScreenService.homeScreen.headerType ?? '1';

    this.showUserClass = this.homeScreenService.homeScreen.userClassVisible!;

    this.isScrollTop = window.scrollY < 1;

    if (this.homeScreenService.homeScreen.homepageLogoImageFileData?.path) {
      this.logoImgUrl =
        this.homeScreenService.homeScreen.homepageLogoImageFileData.path;
    } else if (this.homeScreenService.homeScreen.homepageLogoImageUrl) {
      this.logoImgUrl = this.homeScreenService.homeScreen.homepageLogoImageUrl;
    } else if (this.brand.logoImgFileData?.path) {
      this.logoImgUrl = this.brand.logoImgFileData.path;
    } else if (this.brand.logoImgUrl) {
      this.logoImgUrl = this.brand.logoImgUrl;
    }

    // FIXME: 임시, 설정 값 사용
    // this.headerTransparent = this.homeScreenService.homeScreen.brandId === 103;

    window.addEventListener('scroll', () => {
      this.isScrollTop = window.scrollY < 1;
    });

    this.setDefaultLang();
    this.setUserInfo();

    this.setHeaderTitle(this.location.path());
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setHeaderTitle(event.url);
      }
    });
  }

  /**
   * 브라우저 언어 값 확인 후 초기 표시 언어 설정
   */
  setDefaultLang(): void {
    const defaultLang = this.languageService.lang;
    this.lang = defaultLang;
  }

  /**
   * 크기별 메뉴 표시 여부
   */
  isMenuVisibleByBreakpoint(breakpoint?: string): boolean {
    return this.uiService.isVisibleByBreakpoint(breakpoint);
  }

  /**
   * 사이드 메뉴 토글
   */
  onSideMenuButtonClick(): void {
    // offcanvas 닫을 때 클릭한 요소로 포커스되는 (스크롤) 문제로 인해 data attribute 사용 안함
    Offcanvas.getOrCreateInstance('.offcanvas').toggle();
  }

  /**
   * 언어 변경 메뉴 클릭
   */
  onLanguageMenuButtonClick(lang: string): void {
    this.languageService.changeLang(lang);
  }

  /**
   * 로그인 버튼 클릭 시 로그인 상태에 따라 로그인 페이지로 이동하거나, 로그아웃 처리
   */
  onLoginButtonClick(): void {
    if (this.isLoggedIn) {
      this.authService.logout('', true);
    } else {
      this.login()
        .pipe(
          filter((isLogin) => isLogin),
          tap(() => {
            this.isLoggedIn = true;
          }),
        )
        .subscribe();
    }
  }

  /**
   * 로그아웃 버튼 클릭 시 무조건 로그아웃 처리. login과 동작이 다른 이유는 logout 버튼을 어떻게 표시할지 정책에 따라 달라질 수 있으므로
   *
   * ex> 다른 요소로서 로그아웃 버튼을 만들면 logout 메소드를 바인딩하고, 같은 요소를 활용하면 상태값에 따라 login 메소드로 구분 동작
   */
  onLogoutButtonClick(): void {
    this.authService.logout('', true);
  }

  /**
   * 뒤로가기 버튼 (모바일 제목이 있는 경우 표시) 클릭 시
   *
   * 이전 페이지로 이동
   */
  onBackButtonClick(): void {
    this.location.back();
  }

  /**
   * 회원 정보 갱신
   */
  onRefreshInfoButtonClick(): void {
    this.refreshInfoClick.emit();
  }

  /**
   * 메뉴 클릭
   */
  onMenuButtonClick(path: string): void {
    this.router.navigateByUrl(path);
  }

  /**
   * 회원 가입
   */
  onRegisterButtonClick(): void {
    // 가입 가능하면 회원가입 페이지 이동
    if (this.canRegister) {
      this.router.navigateByUrl('/join');
    } else {
      this.dialogService.alert('ALERT.Req_Register_Travel_Agency').subscribe();
    }
  }

  /**
   * 지원 언어 여부
   */
  isSupportedLanguage(lang: string): boolean {
    return this.languageService.languageSet.has(lang);
  }

  /**
   * 아이콘 메뉴 대신 텍스트 메뉴 여부
   */
  isTextMenu(): boolean {
    return this.headerType === '3';
  }

  isEtcBrand(): boolean {
    return this.brand?.brandType === 'ETC';
  }

  /**
   * 라우팅 경로 조회
   */
  private getRoutePath(activatedRouteList: ActivatedRoute[]): string | null {
    if (activatedRouteList.length) {
      if (activatedRouteList[0].children.length) {
        const path = this.getRoutePath(activatedRouteList[0].children);
        if (path) {
          return path;
        }
      }

      if (
        activatedRouteList[0].routeConfig!.path &&
        !activatedRouteList[0].routeConfig!.data?.['hidden']
      ) {
        return activatedRouteList[0].routeConfig!.path;
      }
    }

    return null;
  }

  /**
   * 모바일 해더 제목 변경
   *
   * FIXME: 방식 변경
   */
  private setHeaderTitle(url: string): void {
    const path = this.getRoutePath(this.activatedRoute.children) ?? url;
    let headerTitle: string;
    let showBorder = true;
    let showHeader = true;
    let showSideMenu = true;
    this.#headerTitleList.some((v) => {
      if (path.startsWith(v.key)) {
        headerTitle = v.headerTitle;
        showBorder = v.showBorder!;
        showHeader = v.showHeader!;
        showSideMenu = v.showSideMenu ?? true;
        return true;
      }
      return false;
    });
    this.showBorder = showBorder;
    this.showSideMenu = showSideMenu;
    this.showHeader = showHeader;
    this.headerTitle = headerTitle!;
  }

  /**
   * 회원 정보 설정
   */
  private setUserInfo(): void {
    if (this.authService.account) {
      this.isLoggedIn =
        this.authService.account?.userInfo?.certType !== 'GUEST';
    }

    this.authService.loginSubject$
      .asObservable()
      .pipe(
        tap(() => {
          this.isLoggedIn =
            this.authService.account != null &&
            this.authService.account?.userInfo?.certType !== 'GUEST';
        }),
      )
      .subscribe();
  }

  /**
   * boolean을 반환하는 로그인 모달
   *
   * 로그인 했으면 무조건 true
   */
  private login(): Observable<boolean> {
    if (this.authService.account) {
      return of(true);
    }

    return this.dialogService.open(LoginComponent).onDialogClosed;
  }
}
