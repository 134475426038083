import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';
import { Observable, finalize, map, tap } from 'rxjs';
import { LanguageService } from '../../services/language.service';
import { PageRepositoryService } from '../abstract-repository.service';
import {
  IBookingMember,
  IBookingMemberExcelDto,
} from '../booking-member/booking-member.model';
import { GlobalRequestHandler } from '../global-request-handler';
import { IHalPageResponse, IPage } from '../page.model';
import { IPayment } from '../payment/payment.model';
import { IBookingCalculationDto } from './booking-calculation.model';
import {
  BookingExchangeRate,
  BookingStatisticsByItem,
  IBooking,
  IBookingGraphDto,
  IBookingHomepageDto,
  IBookingListDto,
  IBookingListUpdateDto,
  IBookingStatsDailyInOutByStartPointDto,
} from './booking.model';

@Injectable({
  providedIn: 'root',
})
export class BookingApi extends PageRepositoryService<IBooking> {
  override baseUri = 'api/booking';

  constructor(
    protected override http: HttpClient,
    private languageService: LanguageService,
  ) {
    super(http);
  }

  findItemNoList(id: number): Observable<any> {
    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/nolist/${id}`)
      .pipe(this.retryUncompleteError());
  }

  findItemHomepage(id: number): Observable<IBookingHomepageDto> {
    return this.http
      .get<IBookingHomepageDto>(
        `${this.apiServerUrl}/${this.baseUri}/homepage/detail/${id}`,
      )
      .pipe(this.retryUncompleteError());
  }

  findPageHomepage(params: any): Observable<IPage<IBooking>> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}/homepage/list`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        map((res) => this.parsePage(res)),
        this.retryUncompleteError(),
      );
  }

  resetSchedule(id: number, booking: IBooking): Observable<IBooking> {
    return this.http
      .put<IBooking>(
        `${this.apiServerUrl}/${this.baseUri}/resetSchedule/${id}`,
        booking,
      )
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  createEstimate(id: number): Observable<IBooking> {
    return this.http
      .post<IBooking>(
        `${this.apiServerUrl}/${this.baseUri}/estimate/${id}`,
        null,
      )
      .pipe(
        GlobalRequestHandler.onAfterCreate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  getMemberBookingHistory(
    userId: number,
    params: any,
  ): Observable<IPage<IBookingListDto>> {
    const httpParams = (<any>this).makeObjToHttpParams({
      memberId: `${userId}`,
      size: '100',
      ...params,
    });

    return this.http
      .get<IHalPageResponse>(
        `${this.apiServerUrl}/${this.baseUri}/memberhistory`,
        {
          params: httpParams,
        },
      )
      .pipe(
        map((res) => this.parsePage(res) as unknown as IPage<IBookingListDto>),
        this.retryUncompleteError(),
      );
  }

  /**
   * 예약 요약
   *
   * @deprecated 중복조회, list 조회 시 사용되는 tourNumberTotalAll 사용
   */
  getSummary(params: any): Observable<any> {
    const httpParams = this.makeObjToHttpParams(params);

    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/summary`, {
        params: httpParams,
      })
      .pipe(this.retryUncompleteError());
  }

  cancelBooking(id: number, body: IBooking): Observable<any> {
    return this.http
      .put(`${this.apiServerUrl}/${this.baseUri}/cancel/${id}`, body)
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  cancelBookingHomepage(id: number, body: IBooking): Observable<any> {
    return this.http
      .put(`${this.apiServerUrl}/${this.baseUri}/homepage/cancel/${id}`, body)
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  confirmBooking(body: any): Observable<any> {
    return this.http
      .put(
        `${this.apiServerUrl}/${this.baseUri}/bookingConfirmation/${body.id}`,
        body,
      )
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  updateBooking(body: any, params: any = {}): Observable<any> {
    return this.http
      .put(
        `${this.apiServerUrl}/${this.baseUri}/updateConfirmation/${body.id}`,
        body,
        { params },
      )
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  updateSchedule(body: any, params: any = {}): Observable<any> {
    return this.http
      .put(`${this.apiServerUrl}/${this.baseUri}/schedule/${body.id}`, body, {
        params,
      })
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  downloadBooking(params: any): Observable<any> {
    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/download`, {
        params: this.makeObjToHttpParams(params),
      })
      .pipe(
        tap(() => {}),
        this.retryUncompleteError(),
      );
  }

  /**
   * 한 예약의 엑셀파일을 다운로드
   *
   * @param {number} id bookingId
   * @param {string} fileName File name
   * @returns {Observable<any>}
   * @memberof BookingService
   */
  downloadBookingMemberList(id: number, fileName: string): Observable<any> {
    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/downloadMember/${id}`, {
        params: { lang: this.languageService.lang },
        responseType: 'blob',
      })
      .pipe(
        tap((res: Blob) => {
          FileSaver.saveAs(res, fileName);
        }),
        this.retryUncompleteError(),
      );
  }

  downloadSchedule(id: number, fileName: string): Observable<any> {
    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/downloadSchedule/${id}`, {
        params: { lang: this.languageService.lang },
        responseType: 'blob',
      })
      .pipe(
        tap((res: Blob) => {
          FileSaver.saveAs(res, fileName);
        }),
        this.retryUncompleteError(),
      );
  }

  downloadEstimate(
    id: number,
    fileName: string,
    firstEstimateFl = false,
  ): Observable<any> {
    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/downloadEstimate/${id}`, {
        params: { lang: this.languageService.lang, firstEstimateFl },
        responseType: 'blob',
      })
      .pipe(
        tap((res: Blob) => {
          FileSaver.saveAs(res, fileName);
        }),
        this.retryUncompleteError(),
      );
  }

  reRegisterBooking(oldBody: any): Observable<any> {
    const { id } = oldBody;
    const newBookingMember: IBookingMember[] = [];

    if (oldBody?.bookingMember?.length > 0) {
      (oldBody.bookingMember as any[]).forEach((member) => {
        const newMember = { ...member };
        delete newMember.id;
        delete newMember.booking;
        newBookingMember.push(newMember);
      });
    }

    const body = {
      ...oldBody,
      bookingMember: newBookingMember,
      id: undefined,
      beforeBookingId: id,
      copied: false,
      deposits: undefined,
    };

    return this.http
      .put(`${this.apiServerUrl}/${this.baseUri}/copy/${id}`, body)
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  getBookingMemberList(params: any): Observable<IBookingMemberExcelDto[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<IBookingMemberExcelDto[]>(
        `${this.apiServerUrl}/${this.baseUri}/bookingMember`,
        {
          params: httpParams,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 해당 예약을 수배 상태로 변경
   */
  estimateBooking(id: number): Observable<any> {
    return this.http
      .put(`${this.apiServerUrl}/${this.baseUri}/estimate/${id}`, {})
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  /**
   * 해당 예약을 수배 상태로 변경
   */
  prepareBooking(id: number): Observable<any> {
    return this.http
      .put(`${this.apiServerUrl}/${this.baseUri}/prepare/${id}`, {})
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  /**
   * EWRC 전용 정산 엑셀 다운로드
   */
  getCalculationXlsx(params: any): Observable<any> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/calculate`, {
        params: httpParams,
        responseType: 'blob',
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 인보이스 작성용 예약 목록 조회
   */
  getBookingListForMonthlyCalc(params: any): Observable<any> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/calc/monthly`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 예약 정산 목록 조회
   */
  getBookingCalcList(params: any): Observable<IBookingCalculationDto[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}/calc`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 테마이라즈 동기화
   *
   * 테마이라즈에서만 취소되거나 변경되고 Booking에 반영되지 않은 정보들을 갱신
   */
  temairazuSync(params: any): Observable<any> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/temairazu/sync`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 관리자 홈 예약 목록 조회
   */
  getBookingAdminHomeList(params: any): Observable<IPage<IBooking>> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<IHalPageResponse>(`${this.apiServerUrl}/${this.baseUri}/adminHome`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        map((res) => this.parsePage(res)),
        this.retryUncompleteError(),
      );
  }

  /**
   * 관리자 홈 예약 목록 조회
   */
  getSimpleBookingList(params: any): Observable<IBookingGraphDto[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}/graph`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 항목별 통계 조회
   */
  getBookingStatisticsByItem(
    params: any,
  ): Observable<BookingStatisticsByItem[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}/statistics/item`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 예약 통계: 항공 출발지별 인원수 엑셀 다운로드
   */
  getBookingStatsDailyInOutByStartPointXlsx(params: any): Observable<any> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get(
        `${this.apiServerUrl}/${this.baseUri}/stats/dailyInOutByStartPoint/xlsx`,
        {
          params: httpParams,
          responseType: 'blob',
        },
      )
      .pipe(
        tap((res: Blob) => {
          FileSaver.saveAs(res, 'daily_pickup');
        }),
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 예약 통계: 항공 출발지별 인원수 조회
   */
  getBookingStatsDailyInOutByStartPoint(
    params: any,
  ): Observable<IBookingStatsDailyInOutByStartPointDto[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(
        `${this.apiServerUrl}/${this.baseUri}/stats/dailyInOutByStartPoint`,
        { params: httpParams },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  payBooking(id: number, paymentList: IPayment[]): Observable<IBooking> {
    return this.http
      .put<any>(`${this.apiServerUrl}/${this.baseUri}/pay/${id}`, {
        body: paymentList,
      })
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  findBookingByTmBookingId(tmBookingId: string): Observable<IBooking> {
    this.isListLoading = true;

    return this.http
      .get<IBooking>(`${this.apiServerUrl}/${this.baseUri}/tm/${tmBookingId}`)
      .pipe(
        tap(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 예약 투어피 정산 초기화
   */
  resetBookingCosting(id: number): Observable<IBooking> {
    return this.http
      .post<any>(
        `${this.apiServerUrl}/${this.baseUri}/${id}/resetBookingCosting`,
        {},
      )
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  /**
   * 예약 환율 변경
   */
  updateExchangeRate(
    id: number,
    exchangeRate: BookingExchangeRate,
  ): Observable<IBooking> {
    return this.http
      .patch<any>(
        `${this.apiServerUrl}/${this.baseUri}/${id}/exchangeRate`,
        exchangeRate,
      )
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  /**
   * 예약 일괄 수정
   */
  updateBookingList(
    data: IBookingListUpdateDto,
    idList: number[],
  ): Observable<void> {
    return this.http
      .patch<any>(`${this.apiServerUrl}/${this.baseUri}/list`, { data, idList })
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  resetScheduleList(
    data: IBookingListUpdateDto,
    idList: number[],
  ): Observable<IBooking> {
    return this.http
      .patch<any>(`${this.apiServerUrl}/${this.baseUri}/list/resetSchedule`, {
        data,
        idList,
      })
      .pipe(
        GlobalRequestHandler.onAfterUpdate$(),
        GlobalRequestHandler.onRequestError$(),
      );
  }

  getDepartingFlightCount(params: any): Observable<number> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}/departingFlightCount`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  getDepartingFlightInfoWithoutSchedule(
    params: any,
  ): Observable<IBookingListDto[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(
        `${this.apiServerUrl}/${this.baseUri}/departingFlightInfoWithoutSchedule`,
        {
          params: httpParams,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  getReturnFlightCount(params: any): Observable<number> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}/returnFlightCount`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  getReturnFlightInfoWithoutSchedule(
    params: any,
  ): Observable<IBookingListDto[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(
        `${this.apiServerUrl}/${this.baseUri}/returnFlightInfoWithoutSchedule`,
        {
          params: httpParams,
        },
      )
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }
}
