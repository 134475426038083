<!-- 모바일(엡) 화면에서 메뉴표시  -->
<!-- 참고 URL https://material.angular.io/components/sidenav/overview -->

<!-- 헤드 부분 (로그인, 메뉴, 로그 ) -->
<!-- 헤드부분 표시  ( 메뉴 및 글로벌 대응에 관한 canChangeLanguage 값 설정  false : 적용안함   true : 적용함   )-->
<app-template-header
  [userInfo]="userInfo"
  [userCard]="userCard"
  [canChangeLanguage]="canChangeLanguage"
  (refreshInfoClick)="onRefreshInfoClick()"
></app-template-header>

<router-outlet></router-outlet>

<!-- 푸터 표시 여부 -->
@if (footerFlg) {
  <app-template-footer></app-template-footer>
}

<!-- 사이드 메뉴 -->
<div class="offcanvas offcanvas-end">
  <app-drawer
    fixedInViewport="true"
    [userInfo]="userInfo"
    [userCard]="userCard"
    (refreshInfoClick)="onRefreshInfoClick()"
  ></app-drawer>
</div>

<app-loading></app-loading>
