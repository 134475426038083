import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';
import { Observable, finalize, map, shareReplay, tap } from 'rxjs';
import { LanguageService } from 'src/lib/services/language.service';
import { PageRepositoryService } from '../abstract-repository.service';
import { IHotelAssign } from './hotel-assign.model';

@Injectable({
  providedIn: 'root',
})
export class HotelAssignApi extends PageRepositoryService<IHotelAssign> {
  override baseUri = 'api/hotelAssign';

  constructor(
    protected override http: HttpClient,
    private languageService: LanguageService,
  ) {
    super(http);
  }

  // TODO: 타입
  /**
   * 식사 배정 목록 조회
   */
  getHotelAssignList(params: any): Observable<IHotelAssign[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}/list`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }

  /**
   * 엑셀 다운로드
   */
  downloadExcel(params: any, fileName: string): Observable<any> {
    const httpParams = this.makeObjToHttpParams({
      ...params,
      lang: this.languageService.lang,
    });

    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/excel`, {
        params: httpParams,
        responseType: 'blob',
      })
      .pipe(
        tap((res: Blob) => {
          FileSaver.saveAs(res, fileName);
        }),
        this.retryUncompleteError(),
      );
  }

  getRoomBookingList(params: any = {}): Observable<any> {
    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/roombookinglist`, {
        params: {
          ...params,
          ...(<any>this)._getSort(params.sort),
          size: params.size || 1000,
        },
      })
      .pipe(
        map((response: any) => {
          return this.parsePage(response);
        }),
        shareReplay(),
      );
  }

  getBookingRoomInfo(params: {
    startDate: string;
    returnDate: string;
    goodsId: any;
  }): Observable<any[]> {
    return this.http.get<any>(`${this.apiServerUrl}/${this.baseUri}/roominfo`, {
      params,
    });
  }

  getOnlineRoomInfo(params: {
    startDate: string;
    returnDate: string;
    goodsId: any;
  }): Observable<any[]> {
    return this.http.get<any>(
      `${this.apiServerUrl}/${this.baseUri}/onlineRoomInfo`,
      {
        params,
      },
    );
  }

  getResortRoomInfo(params: {
    startDate: string;
    returnDate: string;
    goodsId: any;
  }): Observable<any[]> {
    return this.http.get<any>(`${this.apiServerUrl}/${this.baseUri}/roominfo`, {
      params,
    });
  }

  getGraph(params = {}): Observable<any> {
    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}/graph`, {
        params,
      })
      .pipe(
        map((response) => {
          return this.parsePage(response);
        }),
      );
  }
}
