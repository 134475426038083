import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GoodsApi } from '../../lib/repository/goods/goods.api';
import { IGoods } from '../../lib/repository/goods/goods.model';
import { StateItem } from '../../lib/state-item';

@Injectable()
export class HomeLayoutGoodsStateItem extends StateItem<{
  [key: string]: IGoods[];
}> {
  constructor(private goodsApi: GoodsApi) {
    super({});
  }

  loadGoods(idIn: number[]): void {
    if (this.value[idIn.join('_')]) {
      return;
    }

    this.goodsApi
      .findPage({
        goodsIdIn: idIn,
        sort: 'sortNumber,asc',
      })
      .subscribe((page) => {
        this.setValue({ ...this.value, [idIn.join('_')]: page.content });
      });
  }

  getList$(idIn: number[]): Observable<IGoods[]> {
    return this.value$.pipe(map((value) => value[idIn.join('_')]));
  }

  getList(idIn: number[]): IGoods[] {
    return this.value[idIn.join('_')];
  }
}
