<div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
  <div class="modal-content">
    <div class="modal-header">
      <h1 class="modal-title fs-5">
        {{ dialogRef.data!.packagegoods.title }}
        <h6>{{ getSelectedHotelMrhst()?.mrhstNm }}</h6>
      </h1>

      <button
        type="button"
        class="btn-close"
        (click)="onCancelClick()"
      ></button>
    </div>

    <div class="modal-body">
      <div class="head">
        <div class="date-range-wrapper">
          <span class="label">{{ 'Schedule' | translate }}</span>

          <div class="date-range">
            <input
              type="text"
              class="inp"
              [value]="startDate || ('Departure Date' | translate | titlecase)"
              readonly
            />
            <span>-</span>

            <input
              type="text"
              class="inp"
              [value]="returnDate || ('Return Date' | translate | titlecase)"
              readonly
            />
          </div>
        </div>

        <div class="hotel-mrhst">
          <button
            [disabled]="mrhstOnlyOne"
            class="btn btn-outline-secondary hotel-mrhst-select-button"
            (click)="onHotelMrhstSelectButtonClick()"
          >
            {{ '숙박 시설 선택' | translate }}
          </button>
        </div>
      </div>

      <!-- 모바일 표시용 -->
      <div class="hotel-mrhst-content">
        <button
          [disabled]="mrhstOnlyOne"
          class="btn btn-outline-secondary hotel-mrhst-select-button"
          (click)="onHotelMrhstSelectButtonClick()"
        >
          {{ '숙박 시설 선택' | translate }}
        </button>
      </div>

      <div class="content">
        <!-- <button
      type="button"
      class="btn btn-prev"
      (click)="onArrowButtonClick(-1)"
      [style.visibility]="canShow1 ? 'visible' : 'hidden'"
    >
      <i class="bi bi-chevron-left"></i>
    </button> -->

        <div class="calendar-wrapper start">
          <app-calendar
            [year]="year1!"
            [month]="month1!"
            [extraDates]="7"
            [packagegoods]="packagegoods"
            [dataBeforeChange]="dataBeforeChange"
            [canShow]="canShow1"
            [yearList]="yearList"
            [(startDate)]="startDate"
            [(returnDate)]="returnDate"
            [data]="calendarMap"
            [isLoading]="onlineBookingCalendar$ | async"
            [tourTerm]="dialogRef.data!.packagegoods?.tourTerm"
            [tourNumber]="tourNumber"
            [initDate]="initDate1!"
            (monthChange)="onStartCalendarDateChange($event)"
            (dateResetted)="onAnotherDayClick()"
            (dateSelected)="onDateSelected($event)"
            [startPoint]="dialogRef.data!.startPoint"
            [destinationPoint]="dialogRef.data!.destinationPoint"
          >
          </app-calendar>
        </div>

        <!-- <div class="flex-space"></div> -->

        <!-- TODO: 추후 옵션으로 선택 -->
        <!-- <div class="calendar-wrapper end">
      <app-calendar
        [year]="year2!"
        [month]="month2!"
        [packagegoods]="packagegoods"
        [dataBeforeChange]="dataBeforeChange"
        [canShow]="canShow2"
        [yearList]="yearList"
        [(startDate)]="startDate"
        [(returnDate)]="returnDate"
        [data]="calendarMap"
        [isLoading]="onlineBookingCalendar$ | async"
        [tourTerm]="dialogRef.data!.packagegoods?.tourTerm"
        [tourNumber]="tourNumber"
        [initDate]="initDate2!"
        (monthChange)="onEndCalendarDateChange($event)"
        (dateResetted)="onAnotherDayClick()"
        (dateSelected)="onDateSeleted($event)"
        [startPoint]="dialogRef.data!.startPoint"
        [destinationPoint]="dialogRef.data!.destinationPoint"
      >
      </app-calendar>
    </div> -->

        <!-- <button
      type="button"
      class="btn btn-next"
      (click)="onArrowButtonClick(1)"
      [style.visibility]="canShow2 ? 'visible' : 'hidden'"
    >
      <i class="bi bi-chevron-right"></i>
    </button> -->
      </div>
    </div>

    <div class="modal-footer center flex-column">
      @if (isEwrc) {
        @if (calendarMap.size) {
          <span class="text-danger-emphasis">{{
            'ALERT.beforeBookingNotice' | translate
          }}</span>
        }

        <span class="text-primary-emphasis"
          >일본 캘린더가 적용 되어 있으며, 일본 휴일에는 18홀 플레이만
          가능합니다.</span
        >
      }

      <div class="d-flex justify-content-center gap-2">
        <button
          type="button"
          class="btn btn-primary btn-lg text-nowrap"
          color="primary"
          [disabled]="invalid"
          (click)="onOkButtonClick()"
        >
          <!-- <ng-container *ngIf="startDate && returnDate">
        {{ startDate | date: 'yyyy.MM.dd' }}

        <ng-container *ngIf="startDate !== returnDate">
          ~ {{ returnDate | date: 'MM.dd' }}
        </ng-container>

        /
      </ng-container> -->
          {{ 'BUTTON.makeBooking' | translate }}
        </button>

        <button
          type="button"
          class="btn btn-danger btn-lg text-nowrap"
          color="primary"
          (click)="onAnotherDayClick()"
          [disabled]="!startDate"
        >
          {{ 'changeSchedule' | translate }}
        </button>

        <button
          type="button"
          class="btn btn-secondary btn-lg text-nowrap"
          color="primary"
          (click)="onCancelClick()"
        >
          {{ 'BUTTON.Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
