import { Routes } from '@angular/router';
import { homeScreenGuard } from 'src/lib/guards/home-screen.guard';
import { loginGuard } from 'src/lib/guards/login.guard';
import { menuGuard } from 'src/lib/guards/menu.guard';
import { permissionGuard } from 'src/lib/guards/permission.guard';
import { homepageBrandGuard } from '../lib/guards/homepage-brand.guard';
import { homepageMasterResolver } from '../lib/resolvers/homepage-master.resolver';
import { TmBookingDetailComponent } from './components/temairazu/tm-booking-detail/tm-booking-detail.component';
import { TmMrhstBookingComponent } from './components/temairazu/tm-mrhst-booking/tm-mrhst-booking.component';
import { TmMrhstDetailComponent } from './components/temairazu/tm-mrhst-detail/tm-mrhst-detail.component';
import { TemplateLayoutComponent } from './components/template-layout/template-layout.component';

export const routes: Routes = [
  {
    path: '',
    // brand 우선 로드
    canActivate: [homepageBrandGuard],
    // resolve: {
    //   brand: homepageBrandResolver,
    // },
    children: [
      // 페이지 로드 전 config 우선 조회
      {
        path: '',
        resolve: {
          // 홈 설정 및 메뉴 목록
          homepageMaster: homepageMasterResolver,
        },
        children: [
          {
            path: '',
            component: TemplateLayoutComponent,
            children: [
              {
                path: 'home',
                data: { type: 'root' },
                loadChildren: () =>
                  import('./components/home/home.module').then(
                    (m) => m.HomeModule,
                  ),
              },
              {
                path: 'my',
                canActivate: [loginGuard],
                loadChildren: () =>
                  import('./components/my/my.module').then((m) => m.MyModule),
              },
              {
                path: 'notice',
                canActivate: [permissionGuard('BBS$READ')],
                loadChildren: () =>
                  import('./components/notice/notice.module').then(
                    (m) => m.NoticeModule,
                  ),
              },
              {
                path: 'bbs',
                loadChildren: () =>
                  import('./components/bbs-detail/bbs-detail.module').then(
                    (m) => m.BbsDetailModule,
                  ),
              },
              {
                path: 'join',
                loadChildren: () =>
                  import('./components/join/join.module').then(
                    (m) => m.JoinModule,
                  ),
                canActivate: [homeScreenGuard('userSignUp')],
              },
              {
                path: 'menu/:id',
                loadChildren: () =>
                  import('./components/menu/menu.module').then(
                    (m) => m.MenuModule,
                  ),
                canActivate: [menuGuard],
              },
              {
                path: 'package',
                loadChildren: () =>
                  import(
                    './components/packagegoods-list/packagegoods-list.module'
                  ).then((m) => m.PackagegoodsListModule),
                canActivate: [permissionGuard('PACKAGEGOODS$READ')],
              },
              {
                path: 'package/:id',
                loadChildren: () =>
                  import(
                    './components/packagegoods-detail/packagegoods-detail.module'
                  ).then((m) => m.PackagegoodsDetailModule),
                canActivate: [permissionGuard('PACKAGEGOODS$READ')],
              },
              {
                path: 'mrhst/:id',
                loadChildren: () =>
                  import('./components/mrhst-detail/mrhst-detail.module').then(
                    (m) => m.MrhstDetailModule,
                  ),
              },
              {
                path: 'tmmrhst/:id',
                component: TmMrhstDetailComponent,
              },
              {
                path: 'tmmrhst/:id/booking',
                component: TmMrhstBookingComponent,
                canActivate: [loginGuard],
              },
              {
                path: 'tmbooking/:bookingId',
                component: TmBookingDetailComponent,
                canActivate: [loginGuard],
              },
              {
                path: 'hotel/:id',
                loadChildren: () =>
                  import('./components/hotel-detail/hotel-detail.module').then(
                    (m) => m.HotelDetailModule,
                  ),
                canActivate: [permissionGuard('HOTEL_GOODS$READ')],
              },
              {
                path: 'car/:id',
                loadChildren: () =>
                  import(
                    './components/car-goods-detail/car-goods-detail.module'
                  ).then((m) => m.CarGoodsDetailModule),
                canActivate: [permissionGuard('CAR_GOODS$READ')],
              },
              {
                path: 'booking',
                loadChildren: () =>
                  import(
                    './components/booking/booking-list/booking-list.module'
                  ).then((m) => m.BookingListModule),
                canActivate: [loginGuard, permissionGuard('BOOKING$READ')],
              },
              {
                path: 'booking/:bookingId',
                loadChildren: () =>
                  import(
                    './components/booking/booking-detail/booking-detail.module'
                  ).then((m) => m.BookingDetailModule),
                canActivate: [loginGuard, permissionGuard('BOOKING$READ')],
              },
              {
                path: 'booking/:bookingId/complete',
                loadChildren: () =>
                  import(
                    './components/booking/booking-detail-complete/booking-detail-complete.module'
                  ).then((m) => m.BookingDetailCompleteModule),
                canActivate: [loginGuard, permissionGuard('BOOKING$READ')],
              },
              {
                path: 'booking/:bookingId/payment',
                loadChildren: () =>
                  import(
                    './components/booking/booking-payment/booking-payment.module'
                  ).then((m) => m.BookingPaymentModule),
                canActivate: [loginGuard, permissionGuard('BOOKING$READ')],
              },
              {
                path: 'booking/:bookingId/info',
                loadChildren: () =>
                  import(
                    './components/booking/booking-info/booking-info.module'
                  ).then((m) => m.BookingInfoModule),
                canActivate: [loginGuard, permissionGuard('BOOKING$READ')],
              },
              {
                path: 'booking/:bookingId/option',
                loadChildren: () =>
                  import(
                    './components/booking/booking-option/booking-option.module'
                  ).then((m) => m.BookingOptionModule),
                canActivate: [loginGuard, permissionGuard('BOOKING$READ')],
              },
              {
                path: 'company',
                loadChildren: () =>
                  import('./components/brand-list/brand-list.module').then(
                    (m) => m.BrandListModule,
                  ),
              },
              {
                path: 'company/:id',
                loadChildren: () =>
                  import('./components/brand-detail/brand-detail.module').then(
                    (m) => m.BrandDetailModule,
                  ),
              },
              {
                path: 'company-region',
                loadChildren: () =>
                  import(
                    './components/brand-region-list/brand-region-list.module'
                  ).then((m) => m.BrandRegionListModule),
              },
              {
                path: 'company-region/:region',
                loadChildren: () =>
                  import(
                    './components/brand-region-detail-list/brand-region-detail-list.module'
                  ).then((m) => m.BrandRegionDetailListModule),
              },
              {
                path: 'company-region/detail/:id',
                loadChildren: () =>
                  import('./components/brand-detail/brand-detail.module').then(
                    (m) => m.BrandDetailModule,
                  ),
              },
              {
                path: 'goods/:id',
                loadComponent: () =>
                  import(
                    './components/goods-detail/goods-detail.component'
                  ).then((m) => m.GoodsDetailComponent),
              },
              {
                path: 'estimate-booking',
                loadChildren: () =>
                  import(
                    './components/estimate-booking/estimate-booking.module'
                  ).then((m) => m.EstimateBookingModule),
              },
              {
                path: 'estimate-cosmetic',
                loadChildren: () =>
                  import(
                    './components/estimate-cosmetic/estimate-cosmetic.module'
                  ).then((m) => m.EstimateCosmeticModule),
              },

              { path: '', pathMatch: 'full', redirectTo: '/home' },
              { path: '**', redirectTo: 'home' },
            ],
          },
        ],
      },
    ],
  },
];
